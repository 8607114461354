import React from "react";

export default ({ ...props }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ verticalAlign: "middle" }}
    {...props}
  >
    <defs />
    <g id="engineering" fillRule="nonzero" fill="currentColor">
      <path
        d="M5,10.1418003 C6.723,10.5868003 8,12.1378003 8,13.9998003 C8,16.2098003 6.21,17.9998003 4,17.9998003 C1.79,17.9998003 0,16.2098003 0,13.9998003 C0,12.1378003 1.277,10.5868003 3,10.1418003 L3,1.99980026 C3,1.58580026 3.255,1.21480026 3.642,1.06580026 C4.029,0.918800255 4.467,1.02280026 4.743,1.33080026 L11,8.28280026 L11,7.99980026 C11,6.89580026 11.896,5.99980026 13,5.99980026 L16,5.99980026 C17.104,5.99980026 18,6.89580026 18,7.99980026 L18,11.9998003 L20,11.9998003 C20.552,11.9998003 21,12.4478003 21,12.9998003 L21,15.9998003 L11,15.9998003 L11,11.2728003 L5,4.60580026 L5,10.1418003 Z M13,7.99980026 L13,10.9998003 L16,10.9998003 L16,7.99980026 L13,7.99980026 Z M21,16.9998003 C22.656,16.9998003 24,18.3428003 24,19.9998003 C24,21.6568003 22.656,22.9998003 21,22.9998003 L11,22.9998003 C9.344,22.9998003 8,21.6568003 8,19.9998003 C8,18.3428003 9.344,16.9998003 11,16.9998003 L21,16.9998003 Z M21,21.4998003 C21.828,21.4998003 22.5,20.8288003 22.5,19.9998003 C22.5,19.1708003 21.828,18.4998003 21,18.4998003 C20.172,18.4998003 19.5,19.1708003 19.5,19.9998003 C19.5,20.8288003 20.172,21.4998003 21,21.4998003 Z M16,21.4998003 C16.828,21.4998003 17.5,20.8288003 17.5,19.9998003 C17.5,19.1708003 16.828,18.4998003 16,18.4998003 C15.172,18.4998003 14.5,19.1708003 14.5,19.9998003 C14.5,20.8288003 15.172,21.4998003 16,21.4998003 Z M11,21.4998003 C11.828,21.4998003 12.5,20.8288003 12.5,19.9998003 C12.5,19.1708003 11.828,18.4998003 11,18.4998003 C10.172,18.4998003 9.5,19.1708003 9.5,19.9998003 C9.5,20.8288003 10.172,21.4998003 11,21.4998003 Z"
        id="path-1"
      />
    </g>
  </svg>
);
