const brand = {
  aqua: "#1dcecc",
  blue: "#1d52ff",
  red: "#e81770",
  yellow: "#f9cd20", // used to be #fbb934
  white: "#ffffff",
  light: "#bdc4c4",
  lighter: "#e4e7e7",
  dark: "#2f3838",
};

const colors = {
  borderColor: brand.blue,
  bgColor: "#f7f8fc",
  iconColor: brand.red,
  textColor: brand.blue,
  minorTextColor: brand.dark,
  buttonBorderColor: brand.blue,
  buttonBgColor: brand.white,
  buttonFgColor: brand.dark,
  fadedColor: brand.light,
};

module.exports = { brand, colors };
