import React from "react";
import I18n from "../../utils/i18n";

import { StyleSheet, css } from "aphrodite/no-important";
import { Flex, Box } from "grid-styled";
import IconSearch from "react-icons/lib/fa/search";
import IconRemove from "react-feather/dist/icons/x";

import { brand, colors } from "../../styles";

const SearchBar = ({
  placeholder = I18n.t("search_placeholder", { defaultValue: "Search..." }),
  tabIndex = 0,
  value = "",
  hasFocus = false,
  thickBottomBorder = false,
  showRightBorder = true,
  onReset = () => {},
  setValue = () => {},
  setFocus = () => {},
}) => {
  let timesRef, inputRef;

  const beginReset = () => {
    timesRef.className = "";
    setTimeout(() => {
      timesRef.className = css(styles.wiggle);
      onReset();
    }, 1);
  };

  // For consistency, if the props say the SearchBar has focus, make sure it really does
  // have focus, so that everything acts nicely for accessibility
  setTimeout(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, 100);

  return (
    <Flex
      className={css(
        styles.container,
        hasFocus && styles.containerWithFocus,
        thickBottomBorder && styles.containerWithBottomLine,
        !showRightBorder && styles.containerWithoutRightBorder
      )}
      align="center"
    >
      <Box width={20} className={css(styles.searchSymbol)}>
        <IconSearch />
      </Box>
      <Box width={1}>
        <input
          type="text"
          className={css(styles.input)}
          tabIndex={tabIndex}
          placeholder={placeholder}
          value={value}
          onChange={e => {
            setValue(e.target.value);
          }}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          ref={el => {
            inputRef = el;
          }}
        />
      </Box>
      <Box width={20} className={css(styles.resetButton)} onClick={beginReset}>
        {/* Capture reference for CSS animation sequence */}
        <div
          ref={e => {
            timesRef = e;
          }}
          className={css(hasFocus && styles.resetButtonHighlight)}
        >
          <IconRemove />
        </div>
      </Box>
    </Flex>
  );
};

const heightAnim = {
  "0%": { transform: "rotateZ(180deg)" },
  "20%": { transform: "rotateZ(360deg)" },
  "100%": { transform: "rotateZ(720deg)" },
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    border: "1px solid " + colors.fadedColor,
    padding: "1px",
    marginTop: "15px",
    height: 42,
  },

  containerWithFocus: {
    border: "2px solid " + brand.red,
    padding: 0,
  },

  containerWithBottomLine: {
    borderBottom: "2px solid " + brand.red,
    paddingTop: 2,
    "@media (max-width: 803px)": {
      border: "1px solid " + colors.fadedColor,
      paddingTop: 1,
    },
  },

  containerWithoutRightBorder: {
    borderRight: 0,
  },

  input: {
    border: 0,
    lineHeight: "32px",
    fontSize: "15px",
    width: "100%",
    marginLeft: 5,

    ":focus": {
      outline: "none",
    },
    /* do not group these rules */
    "::-webkit-input-placeholder": {
      color: brand.dark,
      fontWeight: 300,
      letterSpacing: "0.8px",
    },
    ":-moz-placeholder": {
      /* FF 4-18 */ color: brand.dark,
      fontWeight: 300,
      letterSpacing: "0.8px",
    },
    "::-moz-placeholder": {
      /* FF 19+ */ color: brand.dark,
      fontWeight: 300,
      letterSpacing: "0.8px",
    },
    ":-ms-input-placeholder": {
      /* IE 10+ */ color: brand.dark,
      fontWeight: 300,
      letterSpacing: "0.8px",
    },
  },

  searchSymbol: {
    marginLeft: "10px",
    paddingBottom: "3px",
  },

  resetButton: {
    color: brand.dark,
    cursor: "pointer",
    paddingTop: 4,
    marginRight: 8,
  },

  resetButtonHighlight: {
    color: brand.red,
  },

  wiggle: {
    animationName: [heightAnim],
    animationDuration: "0.4s",
    animationIterationCount: 1,
    transformOrigin: "60% 40%",
  },
});

export default SearchBar;
