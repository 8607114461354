import React from "react";
// import { Redirect } from "react-router-dom"
import I18n from "../../utils/i18n";

import { StyleSheet, css } from "aphrodite/no-important";
import { Flex, Box } from "grid-styled";
import throttle from "lodash.throttle";

import Spinner from "../../components/Spinner";
import ThinHeader from "../../components/ThinHeader";
import MoreButton from "../../components/MoreButton";
import UnitTile from "../../components/UnitTile";
import SearchBar from "../../components/SearchBar";
import FilterTabs from "../../components/FilterTabs";
import GradeSelect from "../../components/GradeSelect";
import SubjectSelect from "../../components/SubjectSelect";
import SensorSelect from "../../components/SensorSelect";
import FilterPill from "../../components/FilterPill";
import externalLinkTo from "../../utils/externalLinkTo";
import { filters } from "../../types";
import { searchUnitsURI, status } from "../../utils/uri";

const PAGE_SIZE = 12;

const initialTabIndex = 1;

class Search extends React.Component {
  state = {
    results: [],
    resultsLoadVersion: 0,
    featuredResults: [],
    featuredResultsLoadVersion: 0,
    resultsLoading: true,
    moreLoading: false,
    latestUnits: [],
    searchTerm: "",
    searchHasFocus: false,
    selectedFilterTab: 2,
    selectedFilterAccessibleMode: false,
    gradeFilter: null,
    subjectFilter: null,
    sensorFilter: null,
    currentPage: 1,
    totalItems: 0,
  };

  lastLoadVersion = 0;

  isCustomSearch = () => {
    return (
      this.state.searchTerm !== "" ||
      this.state.gradeFilter !== null ||
      this.state.subjectFilter !== null ||
      this.state.sensorFilter !== null
    );
  };

  unsetFilterTab = () => {
    this.setState({
      selectedFilterTab: null,
      selectedFilterAccessibleMode: false,
    });
  };

  setFocusOnSearchbar = hasFocus => {
    this.setState({ searchHasFocus: hasFocus });
    this.unsetFilterTab();
  };

  setFilterTab = userSelectedFilterTab => {
    if (userSelectedFilterTab === this.state.selectedFilterTab) {
      this.setState({
        selectedFilterAccessibleMode: !this.state.selectedFilterAccessibleMode,
      });
    } else {
      this.setState({
        selectedFilterTab: userSelectedFilterTab,
      });
    }
  };

  // Augment the units' lessons with `isSearchMatch` set to true if the lesson matches
  // the search term or fauceted search elements (grade, subject, sensor)
  unitsWithMatchingLessonsHighlighted = () => {
    return this.state.results.map(unit => {
      unit.lessons = unit.lessons.map(lesson => {
        lesson.isSearchMatch =
          !!this.state.gradeFilter ||
          !!this.state.subjectFilter ||
          !!this.state.sensorFilter ||
          !!this.state.searchTerm;

        if (this.state.gradeFilter) {
          if (unit.grades.indexOf(this.state.gradeFilter) === -1) {
            lesson.isSearchMatch = false;
          }
        }
        if (this.state.subjectFilter) {
          if (lesson.subjects.indexOf(this.state.subjectFilter) === -1) {
            lesson.isSearchMatch = false;
          }
        }
        if (this.state.sensorFilter) {
          if (lesson.sensors.indexOf(this.state.sensorFilter) === -1) {
            lesson.isSearchMatch = false;
          }
        }
        if (this.state.searchTerm) {
          // TODO: in future, we should depend on elasticsearch results to tell us what
          //       lessons match the search term. Otherwise, stemming and other inconsistencies
          //       will show up here (e.g. user searches for "dance", result shows "dancing",
          //       but result is not highlighted because in JS land, "dance" != "dancing").
          if (
            (!lesson.description || lesson.description.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) === -1) &&
            (!lesson.title || lesson.title.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) === -1)
          ) {
            lesson.isSearchMatch = false;
          }
        }
        return lesson;
      });
      return unit;
    });
  };

  // Search for Units
  search = async (term, pages = 1, grade, subject, sensor, featured, resultSet = "results") => {
    const result2unit = result => {
      return {
        id: result.id,
        title: result.title,
        description: result.description,
        image: result.thumbnail_image,
        grades: result.grades.map(grade => {
          return grade.key;
        }),
        lessons: result.lessons.map(lesson => {
          return {
            id: lesson.id,
            title: lesson.title,
            description: lesson.description,
            minutes: lesson.estimated_minutes,
            featured: lesson.featured,
            isSearchMatch: false,
            subjects: lesson.subjects.map(subject => {
              return subject.key;
            }),
            sensors: lesson.sensors.map(sensor => {
              return sensor.key;
            }),
          };
        }),
      };
    };

    const params = {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };

    const loadVersion = ++this.lastLoadVersion;

    try {
      this.setState({
        resultsLoading: true,
      });
      // results for the search
      const fetchResponse = await fetch(
        searchUnitsURI({
          query: term,
          grade,
          subject,
          sensor,
          featured,
          pagination: { limit: 12 * pages },
        }),
        params
      );
      const response = await status(fetchResponse);
      const results = (await response.json()).map(result => {
        return result2unit(result);
      });

      let newState = {
        [resultSet]: results,
        [resultSet + "LoadVersion"]: loadVersion,
        resultsLoading: false,
        moreLoading: false,
      };

      if (resultSet === "results") {
        // parse headers for pagination
        newState.totalItems = parseInt(response.headers.get("X-Item-Count"), 10);
      }

      this.setState(prevState => {
        // only update if a request for a more recent search hasn't returned yet
        if (loadVersion > prevState[resultSet + "LoadVersion"]) {
          return newState;
        } else {
          return null;
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  throttledSearch = throttle(this.search, 500, {
    leading: true,
    trailing: true,
  });

  searchWithCurrentState = () => {
    this.throttledSearch(
      this.state.searchTerm,
      this.state.currentPage,
      this.state.gradeFilter,
      this.state.subjectFilter,
      this.state.sensorFilter
    );
  };

  setSearchTerm = term => {
    this.setState({ searchTerm: term, currentPage: 1 }, () => {
      this.searchWithCurrentState();
    });
  };

  resetSearch = () => {
    this.setSearchTerm("");
    // focus on searchbar after animation completes
    setTimeout(() => {
      this.setFocusOnSearchbar(true);
    }, 400);
  };

  nextPage = () => {
    this.setState(
      state => {
        let newPage;
        if (this.hasNextPage(state)) {
          newPage = state.currentPage + 1;
        } else {
          newPage = state.currentPage;
        }
        return { currentPage: newPage, moreLoading: true };
      },
      () => {
        this.searchWithCurrentState();
      }
    );
  };

  hasNextPage = state => {
    state = state || this.state;

    return state.totalItems && state.currentPage * PAGE_SIZE < state.totalItems;
  };

  clickGradeFilter = filter => {
    this.setState({ gradeFilter: filter }, () => {
      this.searchWithCurrentState();
    });
    this.setFocusOnSearchbar(true);
  };

  clickSubjectFilter = filter => {
    this.setState({ subjectFilter: filter }, () => {
      this.searchWithCurrentState();
    });
    this.setFocusOnSearchbar(true);
  };

  clickSensorFilter = filter => {
    this.setState({ sensorFilter: filter }, () => {
      this.searchWithCurrentState();
    });
    this.setFocusOnSearchbar(true);
  };

  constructor(props) {
    super(props);
    this.init();
  }

  async init() {
    // Kick off initial search results request(s)
    await this.search(null, 1, null, null, null, true, "featuredResults");
    this.setState({ resultsLoading: false });
  }

  render() {
    return (
      <div>
        <div className={css(styles.searchSection)}>
          <Flex justify="center" wrap={true}>
            <Box w={600} className={css(styles.searchbar)}>
              <SearchBar
                placeholder={I18n.t("search_units_or_lessons", { defaultValue: "Search units or lessons..." })}
                tabIndex={initialTabIndex}
                value={this.state.searchTerm}
                setValue={this.setSearchTerm}
                hasFocus={this.state.searchHasFocus}
                setFocus={this.setFocusOnSearchbar}
                onReset={this.resetSearch}
                showRightBorder={this.state.selectedFilterTab !== 0}
                thickBottomBorder={this.state.selectedFilterTab !== null}
              />
            </Box>
            <Box>
              <FilterTabs
                tabs={[
                  I18n.t("grade", { defaultValue: "Grade" }),
                  I18n.t("subject", { defaultValue: "Subject" }),
                  I18n.t("sensor", { defaultValue: "Sensor" }),
                ]}
                tabIndices={[initialTabIndex + 1, initialTabIndex + 2, initialTabIndex + 3]}
                selected={this.state.selectedFilterTab}
                underlined={this.state.selectedFilterAccessibleMode && this.state.selectedFilterTab}
                setTab={this.setFilterTab}
                unsetTab={this.unsetFilterTab}
                onClick={this.enstickFilter}
                showLeftBorder={this.state.selectedFilterTab === 0}
              />
            </Box>
          </Flex>
          {this.hasFilters() && this.renderFilters()}
          <Flex justify="center">
            <Box className={css(styles.sizedContainer)}>
              {this.state.selectedFilterTab === 0 && (
                <GradeSelect
                  justify="center"
                  wrap={true}
                  className={css(styles.optionContainer)}
                  tabIndex={this.state.selectedFilterAccessibleMode && initialTabIndex + 1}
                  setGrade={this.clickGradeFilter}
                />
              )}
              {this.state.selectedFilterTab === 1 && (
                <SubjectSelect
                  justify="center"
                  wrap={true}
                  className={css(styles.optionContainer)}
                  tabIndex={this.state.selectedFilterAccessibleMode && initialTabIndex + 2}
                  setSubject={this.clickSubjectFilter}
                />
              )}
              {this.state.selectedFilterTab === 2 && (
                <SensorSelect
                  justify="center"
                  wrap={true}
                  className={css(styles.optionContainer)}
                  tabIndex={this.state.selectedFilterAccessibleMode && initialTabIndex + 3}
                  setSensor={this.clickSensorFilter}
                />
              )}
            </Box>
          </Flex>
        </div>
        {this.state.resultsLoading &&
        !this.state.moreLoading && (
          <div className={css(styles.loading)}>
            <Spinner />
          </div>
        )}
        {this.isCustomSearch() ? this.renderResults() : this.renderSuggestions()}
      </div>
    );
  }

  hasFilters() {
    return this.state.gradeFilter !== null || this.state.subjectFilter !== null || this.state.sensorFilter !== null;
  }

  renderFilters() {
    return (
      <Flex justify="center">
        <Box className={css(styles.sizedContainer)}>
          <Flex wrap={true}>
            {this.state.gradeFilter && (
              <FilterPill
                tabIndex={5}
                label={filters.grade[this.state.gradeFilter]}
                onRemove={() => {
                  this.setState({ gradeFilter: null });
                  this.setFocusOnSearchbar(true);
                }}
              />
            )}
            {this.state.subjectFilter && (
              <FilterPill
                tabIndex={5}
                label={filters.subject[this.state.subjectFilter]}
                onRemove={() => {
                  this.setState({ subjectFilter: null });
                  this.setFocusOnSearchbar(true);
                }}
              />
            )}
            {this.state.sensorFilter && (
              <FilterPill
                tabIndex={5}
                label={filters.sensor[this.state.sensorFilter]}
                onRemove={() => {
                  this.setState({ sensorFilter: null });
                  this.setFocusOnSearchbar(true);
                }}
              />
            )}
          </Flex>
        </Box>
      </Flex>
    );
  }

  renderSuggestions() {
    return (
      <Flex justify="center" align="center" direction="column">
        {this.state.featuredResults.length > 0 && (
          <Box className={css(styles.unitsContainer)}>
            <ThinHeader title={I18n.t("featured_units", { defaultValue: "Featured Units" })} />
            <Flex justify="center" wrap={true}>
              {this.renderUnitTiles(this.state.featuredResults)}
            </Flex>
          </Box>
        )}
        {this.state.latestUnits.length > 0 && (
          <Box>
            <ThinHeader title={I18n.t("recently_updated", { defaultValue: "Recently Updated" })} />
            <Flex justify="center" wrap={true}>
              {this.renderUnitTiles(this.state.latestUnits)}
            </Flex>
          </Box>
        )}
      </Flex>
    );
  }

  renderResults() {
    let more = null;
    if (this.state.moreLoading) {
      more = (
        <div className={css(styles.loading)}>
          <Spinner />
        </div>
      );
    } else if (this.hasNextPage()) {
      more = <MoreButton text={I18n.t("more", { defaultValue: "More" })} onClick={this.nextPage} />;
    }
    return (
      <Flex justify="center">
        {this.state.results.length === 0 &&
        !this.state.resultsLoading && <Box>{I18n.t("no_results", { defaultValue: "No Results" })}</Box>}
        {this.state.results.length > 0 && (
          <Box className={css(styles.unitsContainer)}>
            <ThinHeader title={I18n.t("search_results", { defaultValue: "Search Results" })} />
            <Flex justify="center" wrap={true}>
              {this.renderUnitTiles(this.unitsWithMatchingLessonsHighlighted())}
            </Flex>
            {more}
          </Box>
        )}
      </Flex>
    );
  }

  renderUnitTiles(units) {
    if (units.length === 0) {
      return <Box>{I18n.t("no_units", { defaultValue: "No Units" })}</Box>;
    }
    return units.map((unit, i) => {
      return (
        <Box key={i}>
          <UnitTile
            title={unit.title}
            image={unit.image}
            lessons={unit.lessons}
            hasVideo={unit.video}
            onClickImage={() => {
              this.props.history.push("/units/" + unit.id);
            }}
            onClickLesson={lesson => {
              externalLinkTo({
                path: "/experiments/" + lesson.id,
              })();
            }}
          />
        </Box>
      );
    });
  }
}

const styles = StyleSheet.create({
  searchSection: {
    backgroundColor: "white",
    padding: "15px 0 50px 0",
    marginBottom: "30px",
  },

  searchbar: {
    "@media (max-width: 1034px)": {
      width: 402,
    },
    "@media (max-width: 410px)": {
      width: 297,
    },
  },

  unitsContainer: {
    maxWidth: 1020,
  },

  sizedContainer: {
    width: 1000,
    "@media (max-width: 1034px)": {
      width: 806,
    },
    "@media (max-width: 803px)": {
      width: 402,
    },
    "@media (max-width: 410px)": {
      width: 297,
    },
  },

  optionContainer: {
    marginTop: "20px",
  },

  loading: {
    textAlign: "center",
  },
});

export default Search;
