import React from "react";
import { makeFormField, makeFormControl } from "../forms";

//import MCheckbox from "material-ui/Checkbox";
import MRaisedButton from "material-ui/RaisedButton";
//import MSelectField from "material-ui/SelectField";
import MTextField from "material-ui/TextField";

export class TextField extends React.Component {
  onChange = (event, value) => {
    this.props.fieldProps.onChange(value);
  };

  render() {
    let { fieldProps, ...otherProps } = this.props;

    return (
      <MTextField
        {...otherProps}
        value={fieldProps.value}
        errorText={fieldProps.error}
        onChange={this.onChange}
        onFocus={fieldProps.onFocus}
        disabled={fieldProps.disabled}
      />
    );
  }
}

TextField = makeFormField({ defaultValue: "" }, TextField);

export class Button extends React.Component {
  render() {
    let { is: Component = MRaisedButton, label, submittingLabel, ...otherProps } = this.props;

    if (submittingLabel && (this.props.controlProps.submitting || this.props.controlProps.submitted)) {
      label = submittingLabel;
    }

    return <Component disabled={this.props.controlProps.disabled} label={label} {...otherProps} />;
  }
}

Button = makeFormControl(Button);
