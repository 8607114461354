import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import I18n from "../../utils/i18n";

import axios from "axios";
import { StyleSheet, css } from "aphrodite/no-important";
import { Flex, Box } from "grid-styled";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";

import { forgotPasswordURI } from "../../utils/uri";

class ForgotPassword extends Component {
  state = {
    email: "",
    submitting: false,
    error: null,
  };

  constructor(props) {
    super(props);

    this.sendEmail = async e => {
      e.preventDefault();

      this.setState({
        submitting: true,
        error: null,
      });

      let promise = axios.post(
        forgotPasswordURI(),
        {
          email: this.state.email,
        },
        {
          withCredentials: true,
        }
      );

      try {
        await promise;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          this.setState({
            error: error.response.data.error,
            submitting: false,
          });
        } else {
          this.setState({
            error: I18n.t("we_could_not_process_your_request_right_now_please_try_again", {
              defaultValue: "We couldn't process your request right now. Please try again.",
            }),
            submitting: false,
          });
        }

        return;
      }

      this.setState({ submitting: false, successful: true });
    };

    this.dismiss = () => {
      this.props.history.push("/");
    };

    this.cancel = () => {
      this.props.history.goBack();
    };

    this.handleChangeEmail = value => {
      this.setState({ email: value });
    };
  }

  render() {
    let text;
    let emailBox;
    let buttons;

    if (this.state.successful) {
      text = I18n.t("success_we_sent_an_email_to_email_address_with_a_link_you_can_use_to_reset_your_password", {
        defaultValue: "Success! We sent an email to {{email_address}} with a link you can use to reset your password.",
        email_address: this.state.email,
      });
      buttons = (
        <Box mx={1}>
          <RaisedButton primary label={I18n.t("got_it", { defaultValue: "Got it" })} onClick={this.dismiss} />
        </Box>
      );
    } else {
      text = I18n.t("enter_your_email_below_and_we_will_send_you_a_link_to_reset_your_password", {
        defaultValue: "Enter your email below and we'll send you a link to reset your password.",
      });
      emailBox = (
        <Box className={css(styles.email)}>
          <TextField
            floatingLabelText={I18n.t("enter_your_email", { defaultValue: "Enter your email" })}
            type="text"
            value={this.state.email}
            onChange={(e, newValue) => this.handleChangeEmail(newValue)}
            disabled={this.state.submitting || this.state.successful}
          />
        </Box>
      );
      buttons = [
        <Box mx={1} key={ 1 }>
          <FlatButton
            label={I18n.t("cancel", { defaultValue: "Cancel" })}
            onClick={this.cancel}
            disabled={this.state.submitting}
          />
        </Box>,
        <Box mx={1} key={ 2 }>
          <RaisedButton
            primary
            type="submit"
            label={I18n.t("send_email", { defaultValue: "Send Email" })}
            disabled={this.state.submitting}
          />
        </Box>,
      ];
    }

    return (
      <Dialog open={true} onRequestClose={this.requestClose} contentStyle={{ maxWidth: "400px" }}>
        <Flex direction="column" is="form" onSubmit={this.sendEmail}>
          <Box className={css(styles.header)}>
            {I18n.t("forgot_your_password", { defaultValue: "Forgot your password?" })}
          </Box>
          <Box className={css(styles.subheader)}>{text}</Box>
          {emailBox}
          <Box className={css(styles.errorRow)} my={1}>
            {this.state.error}
          </Box>
          <Box>
            <Flex justify="flex-end" align="baseline" mx={-1}>
              {buttons}
            </Flex>
          </Box>
        </Flex>
      </Dialog>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 500,
  },
  subheader: {
    marginTop: 15,
  },
  email: {},
  errorRow: {
    color: "#eb1c26",
  },
});

export default withRouter(ForgotPassword);
