import React from "react";
import ReactDOM from "react-dom";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Router } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import * as intercom from "./utils/intercom";

import "./index.css";
import "normalize.css";

import AppWithIdentity from "./AppWithIdentity";
import { unregister } from "./registerServiceWorker";

class App extends React.Component {
  // We used to have code here to tell Google Analytics when React Router triggered page views, but we're using
  // Google Tag Manager to wire everything up now, and it allows us to set things up so that an analytics pageview
  // will be triggered automatically whenever a pushState or popState happens. This logic will need to be resurrected
  // if we ever switch away from Google Tag Manager but keep Google Analytics around.
  // Note that we still track history for Intercom manually, mostly because I haven't yet decided whether it should
  // also be managed by Google Tag Manager.
  history = createHistory();

  componentDidMount() {
    intercom.boot();

    this.historyUnlisten = this.history.listen(location => {
      intercom.pageview();
    });
  }

  componentWillUnmount() {
    this.historyUnlisten();
  }

  render() {
    return (
      <MuiThemeProvider>
        <Router history={this.history}>
          <AppWithIdentity />
        </Router>
      </MuiThemeProvider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

/**
 * For some reason, the service worker caches things very aggressively, and it's difficult
 * to get new code to work in browsers. Commenting out for now.
 */
// registerServiceWorker()
unregister();
