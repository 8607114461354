import React from "react";

import { StyleSheet, css } from "aphrodite/no-important";
import { Box } from "grid-styled";
import IconClose from "react-icons/lib/fa/close";

import Button from "../Button";
import { brand, colors } from "../../styles";

const FilterPill = ({ label, tabIndex = 0, onRemove = () => {}, ...otherProps }) => (
  <Box className={css(styles.pill)} {...otherProps}>
    <div className={css(styles.label)}>{label}</div>
    <Button tabIndex={tabIndex} className={css(styles.remove)} onClick={onRemove}>
      <IconClose />
    </Button>
    {/* Final space keeps the correct under the `remove` button */}
    &nbsp;
  </Box>
);

const styles = StyleSheet.create({
  pill: {
    borderRadius: "15px",
    backgroundColor: colors.fadedColor,
    display: "table",
    padding: "4px 2px 2px 12px",
    margin: "15px 10px 5px 0px",
  },

  label: {
    color: brand.dark,
    display: "table-cell",
    paddingRight: "15px",
    lineHeight: "20px",
  },

  remove: {
    padding: 0,
    color: brand.dark,
    display: "table-cell",
    position: "relative",
    top: "-1px",
    borderRadius: "100%",

    ":focus": {
      outline: "none",
    },
    ":focus::before": {
      content: "''",
      position: "absolute",
      width: 20,
      height: 20,
      marginLeft: -4,
      marginTop: -3,
      borderRadius: "100%",
      border: "2px solid " + brand.dark,
    },
  },
});

export default FilterPill;
