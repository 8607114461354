import React from "react";
import PropTypes from "prop-types";
import I18n from "../../utils/i18n";

import { css } from "aphrodite/no-important";
import { Flex, Box } from "grid-styled";
import { ReactHeight } from "react-height";

import IconPlay from "react-icons/lib/fa/play-circle";
import IconExclame from "../../images/blBoxedExclame";

import TitledImage from "../TitledImage";
import Button from "../Button";

import styles from "./styles";
import truncate from "../../utils/truncate";
import fnNull from "../../utils/fnNull";
import { imageWidth } from "./defaults";

const naturalColumnHeight = 144;

class UnitTile extends React.Component {
  state = {
    expanded: false,
    lessonHasBeenHovered: {},
    lessonColumnHeight: null,
  };

  titledImageRef = null;

  focusTitle() {
    this.titledImageRef.focus();
    this.titledImageRef.selectAll();
  }

  setHeight = height => {
    this.setState({
      lessonColumnHeight: height,
    });
  };

  expandColumn = () => {
    this.setState({
      expanded: true,
    });
  };

  hasClickImageFunction = () => {
    return this.props.onClickImage !== fnNull;
  };

  searchResultCount = () => {
    let count = 0;
    for (let i = 0; i < this.props.lessons.length; i++) {
      if (this.props.lessons[i].isSearchMatch) {
        count++;
      }
    }
    return count;
  };

  lessonsPrioritizedByMatchingSearchResults = () => {
    let lessons = [];
    for (let i = 0; i < this.props.lessons.length; i++) {
      if (this.props.lessons[i].isSearchMatch) {
        lessons.push(this.props.lessons[i]);
      }
    }
    for (let i = 0; i < this.props.lessons.length; i++) {
      if (!this.props.lessons[i].isSearchMatch) {
        lessons.push(this.props.lessons[i]);
      }
    }
    return lessons;
  };

  render() {
    return (
      <div className={css(styles.outline)}>
        <TitledImage
          ref={el => (this.titledImageRef = el)}
          className={css(styles.image)}
          title={this.props.title}
          image={this.props.image}
          width={imageWidth}
          onClick={this.hasClickImageFunction() && this.props.onClickImage}
          tabIndex={this.props.tabIndex}
          canEditInPlace={this.props.canEditInPlace}
          isEditing={this.props.isEditing}
          startEditing={this.props.startEditing}
          stopEditing={this.props.stopEditing}
          setTitle={this.props.setTitle}
        />
        {this.props.hasVideo && (
          <div className={css(styles.videoIndicator)}>
            <IconPlay />
          </div>
        )}
        <Flex
          direction="column"
          justify="space-between"
          style={{ height: this.state.expanded ? "auto" : null }}
          className={css(styles.container)}
        >
          <Box>
            <Flex
              direction="column"
              style={{
                height: this.state.expanded ? this.state.lessonColumnHeight : null,
              }}
              className={css(styles.list, this.props.isScrollable && styles.listScrollable)}
            >
              <ReactHeight onHeightReady={this.setHeight}>
                {this.lessonsPrioritizedByMatchingSearchResults().map(this.renderLesson, this)}
              </ReactHeight>
            </Flex>
          </Box>
          {this.showFooter() ? this.renderFooter() : this.renderAbsentFooter()}
        </Flex>
      </div>
    );
  }

  showFooter() {
    return this.state.lessonColumnHeight > naturalColumnHeight && !this.state.expanded;
  }

  renderLesson(lesson, i) {
    return (
      <div
        className={css(styles.lesson, lesson.isSearchMatch && styles.lessonSearchMatch)}
        key={"lesson-" + i + "-" + lesson.id}
      >
        {lesson.featured && <IconExclame className={css(styles.featuredLessonIndicator)} />}

        <Button
          className={css(styles.lessonButton, this.state.lessonHasBeenHovered[i] && styles.lessonButtonAfterFirstHover)}
          onClick={() => {
            this.props.onClickLesson(lesson);
          }}
          onMouseOver={() => {
            // Keep track of which lessons have been hovered, so we can
            // show a nice mouse-out animation without showing an initial
            // mouse-out when the page loads
            let updatedHovered = this.state.lessonHasBeenHovered;
            updatedHovered[i] = true;
            this.setState({
              lessonHasBeenHovered: updatedHovered,
            });
          }}
        >
          <Flex justify="space-between" align="baseline" className={css(styles.lessonHeader)}>
            <Box className={css(styles.lessonTitle)}>{lesson.title}</Box>
            <Box className={css(styles.lessonMinutes)}>
              {I18n.t("lesson_minutes", { defaultValue: "{{lesson_minutes}} mins", lesson_minutes: lesson.minutes })}
            </Box>
          </Flex>
          <div className={css(styles.lessonDescription)}>{truncate(lesson.description, 120)}</div>
        </Button>
      </div>
    );
  }

  renderFooter() {
    return (
      <Box className={css(styles.footer)}>
        <Button onClick={this.expandColumn}>
          {this.searchResultCount() > 0 ? (
            this.renderSearchTotal()
          ) : (
            I18n.t("lesson_count_lessons_total", {
              defaultValue: "{{lesson_count}} Lessons Total",
              lesson_count: this.props.lessons.length,
            })
          )}
        </Button>
      </Box>
    );
  }

  renderSearchTotal() {
    return (
      <span>
        <span className={css(styles.footerSearchTotal)}>
          {I18n.t("search_result_count_matching", {
            defaultValue: "{{search_result_count}} Matching",
            search_result_count: this.searchResultCount(),
          })}
        </span>
        <span className={css(styles.footerSearchSeparator)}>&middot;</span>
        {I18n.t("lesson_count_lessons_total", {
          defaultValue: "{{lesson_count}} Lessons Total",
          lesson_count: this.props.lessons.length,
        })}
      </span>
    );
  }

  renderAbsentFooter() {
    return <div className={css(styles.absentFooter)} />;
  }
}

UnitTile.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  lessons: PropTypes.array,
  canClickImage: PropTypes.bool,
  onClickImage: PropTypes.func,
  onClickLesson: PropTypes.func,
  isScrollable: PropTypes.bool,
  tabIndex: PropTypes.number,
  hasVideo: PropTypes.bool,

  canEditInPlace: PropTypes.bool,
  isEditing: PropTypes.bool,
  startEditing: PropTypes.func,
  stopEditing: PropTypes.func,
  setTitle: PropTypes.func,
};

UnitTile.defaultProps = {
  lessons: [],
  onClickImage: fnNull,
  onClickLesson: fnNull,
  isScrollable: false,
  tabIndex: 0,
  hasVideo: false,

  canEditInPlace: false,
  isEditing: false,
  startEditing: e => {},
  stopEditing: () => {},
  setTitle: t => {},
};

export default UnitTile;
