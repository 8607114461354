import React from "react";
import I18n from "../../utils/i18n";

import { StyleSheet, css } from "aphrodite/no-important";
import { Flex, Box } from "grid-styled";

import TitledImage from "../../components/TitledImage";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";

import IconExclame from "../../images/blBoxedExclame";

import externalLinkTo from "../../utils/externalLinkTo";
import { getUnitURI, status } from "../../utils/uri";
import truncate from "../../utils/truncate";
import { brand } from "../../styles";

class Unit extends React.Component {
  state = {
    unit: null,
    lessonHasBeenHovered: {},
  };

  constructor(props) {
    super(props);

    this.getUnit(props.match.params.id);
  }

  getUnit = async unitId => {
    const result2unit = result => {
      return {
        id: result.id,
        title: result.title,
        description: result.description,
        image: result.thumbnail_image,
        video: result.video,
        lessons: result.lessons.map(lesson => {
          return {
            id: lesson.id,
            title: lesson.title,
            description: lesson.description,
            minutes: lesson.estimated_minutes,
            featured: lesson.featured,
          };
        }),
      };
    };

    const params = {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const fetchResponse = await fetch(getUnitURI({ unitId }), params);
      const response = await status(fetchResponse);
      const result = result2unit(await response.json());
      this.setState({ unit: result });
    } catch (e) {
      console.error(e);
    }
  };

  featuredLessonCount = () => {
    let count = 0;
    if (this.state.unit) {
      const lessons = this.state.unit.lessons;
      for (let i = 0; i < lessons.length; i++) {
        if (lessons[i].featured) {
          count++;
        }
      }
    }
    return count;
  };

  render() {
    const unit = this.state.unit;

    return (
      <div>
        <div className={css(styles.mediaContainer)}>{unit ? this.renderMedia() : <Spinner />}</div>
        <div className={css(styles.content)}>
          {unit && (
            <div className={css(styles.lessonMeta)}>
              <span>
                {I18n.t("lesson_count_lessons", {
                  defaultValue: "{{lesson_count}} Lessons",
                  lesson_count: unit.lessons.length,
                })}
              </span>
              {this.featuredLessonCount() > 0 && (
                <span>
                  <IconExclame className={css(styles.legendIcon)} />
                  {I18n.t("featured_lesson_count_featured", {
                    defaultValue: "{{featured_lesson_count}} featured",
                    featured_lesson_count: this.featuredLessonCount(),
                  })}
                </span>
              )}
            </div>
          )}
          <div className={css(styles.lessonsContainer)}>{unit && unit.lessons.map(this.renderLesson, this)}</div>
        </div>
      </div>
    );
  }

  renderMedia() {
    const unit = this.state.unit;
    if (unit.video) {
      return (
        <iframe
          title={I18n.t("video", { defaultValue: "Video" })}
          id="ytplayer"
          type="text/html"
          width="720"
          height="405"
          src={`https://www.youtube.com/embed/${unit.video.identifier}?modestbranding=1&rel=0&color=white`}
          frameBorder="0"
          allowFullScreen
        />
      );
    } else {
      return (
        <Flex justify="center">
          <Box>
            <TitledImage className={css(styles.image)} title={unit.title} image={unit.image} />
          </Box>
        </Flex>
      );
    }
  }

  renderLesson(lesson, i) {
    return (
      <div key={"lesson-" + i + "-" + lesson.id} className={css(styles.lessonsInner)}>
        {lesson.featured && <IconExclame className={css(styles.featuredLessonIndicator)} />}
        <Button
          className={css(styles.lesson, this.state.lessonHasBeenHovered[i] && styles.lessonAfterFirstHover)}
          onClick={externalLinkTo({
            path: "/experiments/" + lesson.id,
          })}
        >
          <div className={css(styles.lessonTitle)}>{lesson.title}</div>
          <div className={css(styles.lessonDescription)}>{truncate(lesson.description, 200)}</div>
        </Button>
      </div>
    );
  }
}

const animStateSelected = {
  borderLeft: "4px solid " + brand.red,
};
const animStateNormal = {
  borderLeft: "4px solid rgba(255, 255, 255, 0.0)",
};
const animHover = { from: animStateNormal, to: animStateSelected };
const animUnhover = { from: animStateSelected, to: animStateNormal };

const styles = StyleSheet.create({
  container: {
    marginTop: 15,
  },

  mediaContainer: {
    backgroundColor: brand.dark,
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },

  image: { margin: "15px 0" },

  content: {
    maxWidth: 1024,
    marginLeft: "auto",
    marginRight: "auto",
  },

  lessonsContainer: {
    marginTop: 15,
    marginBottom: 15,
  },

  lessonsInner: {
    position: "relative",
    padding: 15,
    backgroundColor: brand.white,
    borderBottom: "1px solid " + brand.lighter,
  },

  lesson: {
    width: "100%",
    marginTop: 2,
    paddingLeft: 15,

    borderLeft: "4px solid rgba(255, 255, 255, 0.0)",

    ":focus": {
      outline: "none",
      borderLeft: "4px solid " + brand.red,
    },
    ":hover": {
      animationName: [animHover],
      animationDuration: "0.3s",
      animationFillMode: "forwards",
      animationIterationCount: 1,
    },
  },

  lessonAfterFirstHover: {
    animationName: [animUnhover],
    animationDuration: "0.5s",
    animationFillMode: "forwards",
    animationIterationCount: 1,
  },

  lessonTitle: {
    fontWeight: 500,
    color: brand.blue,
  },

  lessonDescription: {
    fontWeight: 400,
  },

  lessonMeta: {
    color: brand.aqua,
    fontWeight: 500,
    textAlign: "center",
    width: "100%",
    marginTop: 15,
    padding: "15px 0px",
    backgroundColor: brand.white,
  },

  featuredLessonIndicator: {
    position: "absolute",
    width: 16,
    height: 16,
    left: 18,
    top: 16,
    color: brand.aqua,
  },

  legendIcon: {
    paddingLeft: 20,
    paddingBottom: 5,
    width: 16,
    height: 16,
    color: brand.aqua,
  },
});

export default Unit;
