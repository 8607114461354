import React from "react";
import I18n from "../../utils/i18n";
import { StyleSheet, css } from "../../utils/aphrodite";
import { brand } from "../../styles";
import URI from "urijs";
import ReactHeight from "react-height";
import { setNoticeBarHeight } from "../../utils/intercom";

export default class NoticeBar extends React.Component {
  state = {
    height: 0,
  };

  renderStagingBar = () => {
    if (window.location.hostname.indexOf("staging") !== -1) {
      let url = URI("https://lessons.becauselearning.com")
        .pathname(this.props.location.pathname)
        .search(this.props.location.search)
        .hash(this.props.location.hash)
        .toString();

      return (
        <Notice
          color="yellow"
          action={<a href={url}>{I18n.t("view_the_production_site", { defaultValue: "View the production site" })}</a>}
        >
          {I18n.t("you_are_viewing_a_testing_site_which_will_reset_every_week", {
            defaultValue: "You are viewing a testing site, which will reset every week.",
          })}
        </Notice>
      );
    }
  };

  heightChanged = height => {
    this.setState({
      height: height,
    });
    setNoticeBarHeight(height);
  };

  render() {
    return (
      <div>
        <div className={css(styles.container)}>
          <ReactHeight onHeightReady={this.heightChanged}>{this.renderStagingBar()}</ReactHeight>
        </div>
        <div style={{ height: this.state.height }} />
      </div>
    );
  }
}

class Notice extends React.Component {
  render() {
    return (
      <div className={css(styles.bar, styles[this.props.color])}>
        {this.props.children}
        <div className={css(styles.action)}>{this.props.action}</div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: "fixed",
    left: "0",
    bottom: "0",
    right: "0",
    zIndex: "10",
    fontSize: "1.1em",
  },
  bar: {
    padding: "20px",
    "-> a": {
      textDecoration: "none",
      fontWeight: "500",
    },
  },
  action: {
    float: "right",
  },
  yellow: {
    color: "black",
    backgroundColor: brand.yellow,
    "-> a": {
      color: brand.blue,
    },
  },
  red: {
    color: "white",
    backgroundColor: brand.red,
    "-> a": {
      color: "white",
    },
  },
});
