import React, { Component } from 'react';

import { StyleSheet, css } from "aphrodite/no-important";

import RaisedButton from "material-ui/RaisedButton";

export default class OAuthButton extends Component {
  render() {
    return <RaisedButton
      className={css(styles.button)}
      label={
        <span className={css(styles.text)}>
          <img src={`/images/oauth/${this.props.provider}.png`} className={css(styles.image)}/>
          {this.props.label}
        </span>
      }
      onClick={this.props.onClick}
      size='large'
    />
  }
}

const styles = StyleSheet.create({
  button: {
    margin: '3px',
    whiteSpace: 'nowrap'
  },
  text: {
    textTransform: 'none',
    verticalAlign: 'middle'
  },
  image: {
    width: '24px',
    verticalAlign: 'middle',
    paddingBottom: '4px',
    paddingRight: '14px'
  }
});
