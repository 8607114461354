import React from "react";

export function errorFor(errors) {
  if (errors) {
    return errors.map(error => {
      return <div>{error}</div>;
    });
  } else {
    return null;
  }
}
