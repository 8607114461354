import React, { Component } from "react";
import I18n from "../../utils/i18n";
import axios from "axios";
import { StyleSheet, css } from "aphrodite/no-important";
import { resetPasswordURI } from "../../utils/uri";
import { errorFor } from "../../utils/forms";
import { brand } from "../../styles";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { Flex, Box } from "grid-styled";
import { withRouter } from "react-router-dom";

const MIN_PASSWORD_LENGTH = 8;

class Page extends Component {
  state = {
    password: "",
    errors: {},
    resetting: false,
  };

  requestClose = () => {
    this.props.history.push("/");
  };

  resetPassword = async event => {
    event.preventDefault();

    // would be nice to move some of this validation serverside so it stays consistent everywhere that uses it
    if (this.state.password.length < MIN_PASSWORD_LENGTH) {
      this.setState({
        errors: {
          password: [
            I18n.t("your_password_should_have_a_minimum_of_eight_characters", {
              defaultValue: "Your password should have a minimum of 8 characters",
            }),
          ],
        },
      });

      return;
    }

    this.setState({
      resetting: true,
    });

    let promise = axios.post(resetPasswordURI(), {
      reset_password_token: this.props.match.params.token,
      password: this.state.password,
    });

    let response;
    try {
      response = await promise;
    } catch (error) {
      this.setState({
        resetting: false,
        errors: {
          other: I18n.t("we_could_not_process_your_request_right_now_please_try_again", {
            defaultValue: "We couldn't process your request right now. Please try again.",
          }),
        },
      });

      return;
    }

    if (response.data.status === "ok") {
      this.requestClose();
    } else {
      this.setState({
        resetting: false,
        errors: response.data.errors,
      });
    }
  };

  passwordChanged = (event, value) => {
    this.setState({
      password: value,
      errors: {},
    });
  };

  render() {
    let otherError = null;
    if (this.state.errors.reset_password_token && this.state.errors.reset_password_token[0] === "is invalid") {
      // TODO: button to generate a new token
      otherError = I18n.t("your_reset_password_token_has_expired", {
        defaultValue: "Your reset password token has expired.",
      });
    } else if (this.state.errors.other) {
      otherError = this.state.errors.other;
    }

    return (
      <Dialog open={true} onRequestClose={this.requestClose} contentStyle={{ maxWidth: "400px" }}>
        <Flex is="form" direction="column" onSubmit={this.resetPassword}>
          <Box className={css(styles.header)}>
            {this.props.initial ? (
              I18n.t("set_password", { defaultValue: "Set Password" })
            ) : (
              I18n.t("reset_password", { defaultValue: "Reset Password" })
            )}
          </Box>
          <Box className={css(styles.passwordFieldBox)} px={2} mb={2}>
            <TextField
              floatingLabelText={I18n.t("enter_a_password", { defaultValue: "Enter a password" })}
              type="password"
              value={this.state.password}
              onChange={this.passwordChanged}
              errorText={errorFor(this.state.errors.password)}
              fullWidth
            />
          </Box>
          <Box className={css(styles.error)} mb={2}>
            {otherError}
          </Box>
          <Box>
            <Flex justify="flex-end" mx={-1}>
              <Box mx={1}>
                <FlatButton
                  label={I18n.t("cancel", { defaultValue: "Cancel" })}
                  onClick={this.requestClose}
                  disabled={this.state.resetting}
                />
              </Box>
              <Box mx={1}>
                <RaisedButton
                  primary
                  type="submit"
                  label={I18n.t("save", { defaultValue: "Save" })}
                  disabled={this.state.resetting}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Dialog>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    margin: "20px",
    fontSize: "22px",
    textAlign: "center",
    color: "black",
  },
  passwordFieldBox: {
    marginBottom: "16px",
  },
  error: {
    textAlign: "center",
    minHeight: "18px",
    color: brand.red,
  },
});

Page = withRouter(Page);

export function SetPassword(props) {
  return <Page initial={true} {...props} />;
}

export function ResetPassword(props) {
  return <Page initial={false} {...props} />;
}
