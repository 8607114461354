import React from "react";
import I18n from "../utils/i18n";

import { withRouter, Route, Switch } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
import { observer } from "mobx-react";

//import { signIn, signOut, loadStatus } from "../utils/user";
import CurrentUser from "../stores/CurrentUser";
import timeout from "../utils/timeout";

import Header from "../components/Header";
import NoticeBar from "../components/NoticeBar";

import Search from "../pages/Search";
import SignIn from "../pages/SignIn";
import ForgotPassword from "../pages/ForgotPassword";
import AssembleUnit from "../pages/AssembleUnit";
import Unit from "../pages/Unit";
import Subscribe from "../pages/Subscribe";
import CardUpdateForm from "../components/CardUpdateForm";
import { SetPassword, ResetPassword } from "../pages/ResetPassword";

const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

class AppWithIdentity extends React.Component {
  state = {
    signInErrorMessage: "",
  };

  constructor(props) {
    super(props);

    this.handleSignIn = async (email, password) => {
      try {
        await CurrentUser.signIn(email, password);
        this.props.history.goBack();
      } catch (error) {
        let msg;
        if (error.response && error.response.status === 401) {
          msg = I18n.t("the_email_or_password_you_entered_is_not_right", {
            defaultValue: "The email or password you entered isn't right.",
          });
        } else {
          msg = I18n.t("we_could_not_process_your_request_right_now_please_try_again", {
            defaultValue: "We couldn't process your request right now. Please try again.",
          });
        }
        this.setState({
          signInErrorMessage: msg,
        });
      }
    };

    this.handleOAuthSignIn = async (user, redirect) => {
      await CurrentUser.loadStatus();
      if (redirect) {
        window.location.href = redirect;
      } else {
        this.props.history.goBack();
      }
    }

    this.handleOAuthSignInFailed = error => {
      this.setState({
        signInErrorMessage: error
      });
    }

    this.handleSignOut = async () => {
      /**
             * Provide enough time for animation of menu to complete before taking its
             * anchor element away.
             */
      await timeout(300);
      await CurrentUser.signOut();
    };
  }

  async componentDidMount() {
    await CurrentUser.loadStatus();
  }

  render() {
    return (
      <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
        <div>
          <Header
            isSignedIn={!!CurrentUser.user}
            signedInName={CurrentUser.user && CurrentUser.user.name}
            onSignOut={this.handleSignOut}
          />
          <Route exact path="/" component={Search} />
          <Route
            path="/sign_in"
            render={() => <SignIn errorMessage={this.state.signInErrorMessage} onSignIn={this.handleSignIn} onOAuthSignIn={this.handleOAuthSignIn} onOAuthSignInFailed={this.handleOAuthSignInFailed} />}
          />
          <Route path="/forgot_password" component={ForgotPassword} />
          <Route path="/units/assemble" component={AssembleUnit} />
          <Route path="/units/:id([0-9]+)" component={Unit} />
          <Switch>
            <Route path="/subscribe/:plan/discounts/:discount" component={Subscribe} />
            <Route path="/subscribe/:plan" component={Subscribe} />
          </Switch>
          <Route path="/password/set/:token" component={SetPassword} />
          <Route path="/password/reset/:token" component={ResetPassword} />
          <Route path="/update_card_info" component={CardUpdateForm} />
          <Route path="/" component={NoticeBar} />
        </div>
      </StripeProvider>
    );
  }
}

export default withRouter(observer(AppWithIdentity));
