import React from "react";

import { StyleSheet, css } from "aphrodite/no-important";
import { Flex, Box } from "grid-styled";

import Button from "../Button";
import { colors } from "../../styles";

const IconOption = ({ id = 0, label = "Option", tabIndex = 0, onClick = e => {}, selected = false, children }) => (
  <Button className={css(styles.option, selected && styles.optionSelected)} onClick={onClick} tabIndex={tabIndex}>
    <Flex direction="column" align="center">
      <Box>{children}</Box>
      <Box className={css(styles.label)}>{label}</Box>
    </Flex>
  </Button>
);

const animStateHighlighted = {
  backgroundColor: "white",
  boxShadow: "0px 6px 30px -3px rgba(0,0,0,0.5)",
  borderColor: colors.borderColor,
};
const animStateNormal = {
  backgroundColor: colors.backgroundColor,
  boxShadow: "0px 6px 30px -30px rgba(0,0,0,0.1)",
  borderColor: colors.bgColor,
};
const highlightAnim = { from: animStateNormal, to: animStateHighlighted };

const styles = StyleSheet.create({
  label: {
    marginTop: "5px",
  },

  option: {
    padding: "5px 10px",
    margin: "5px 15px",
    borderRadius: "2px",
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderColor: "white",
    minWidth: "50px",

    ":focus": {
      // Remove default outline when option has focus
      outline: "none",

      // Animate in a box shadow and white background
      animationName: [highlightAnim],
      animationDuration: "0.25s",
      animationFillMode: "forwards",
      animationIterationCount: 1,
    },
  },

  optionSelected: {
    borderColor: colors.borderColor,
  },
});

export default IconOption;
