import axios from "axios";
import { signInURI, signOutURI, currentUserURI, status } from "./uri";
import * as intercom from "./intercom";

export const data2user = data => {
  return {
    id: data.id,
    name: `${data.first_name} ${data.last_name}`,
    email: data.email,
    intercom_user_hash: data.intercom_user_hash,
  };
};

export const signIn = async (email, password, rememberMe = false) => {
  const response = await axios.post(
    signInURI(),
    {
      user: {
        email: email,
        password: password,
      },
    },
    {
      withCredentials: true,
    }
  );

  updateIntercomUserInfo(response.data);

  return data2user(response.data);
};

export const signOut = async () => {
  const params = {
    method: "DELETE",
    credentials: "include",
  };

  try {
    const fetchResponse = await fetch(signOutURI(), params);
    await status(fetchResponse);
    intercom.logout();
    return true;
  } catch (e) {
    return false;
  }
};

export const loadStatus = async () => {
  const response = await axios.get(currentUserURI(), {
    withCredentials: true,
  });

  updateIntercomUserInfo(response.data);

  return data2user(response.data);
};

function updateIntercomUserInfo(data) {
  // The data we're pushing into Intercom here needs to match up with the data ardusat-web pushes in
  // gulp/app/users/user.service.js in order to not confuse Intercom when users flip back and forth between
  // ardusat-web and ehub2.
  intercom.updateUserInfo({
    email: data.email,
    user_id: data.id,
    user_hash: data.intercom_user_hash,
    username: data.username,
  });
}
