import { listHeight, tileMargin, imageWidth, imageHeight, marginTopOverhang } from "./defaults";

import { StyleSheet } from "aphrodite/no-important";
import { brand, colors } from "../../styles";

const animStateSelected = {
  borderLeft: "4px solid " + brand.red,
};
const animStateNormal = {
  borderLeft: "4px solid rgba(255, 255, 255, 0.0)",
};
const animHover = { from: animStateNormal, to: animStateSelected };
const animUnhover = { from: animStateSelected, to: animStateNormal };

export default StyleSheet.create({
  outline: {
    position: "relative",
    background: "#fff",
    padding: "0 " + (tileMargin + "px"),
    marginTop: marginTopOverhang,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 15,
    boxShadow: "0px 6px 30px -6px rgba(0,0,0,0.15)",
  },

  image: {
    position: "absolute",
    marginTop: -marginTopOverhang,
    boxShadow: "0px 14px 42px -16px rgba(0,0,0,0.75)",
  },

  container: {
    width: imageWidth,
    height: marginTopOverhang + listHeight,
    marginTop: marginTopOverhang,
    paddingTop: imageHeight - marginTopOverhang + 5,
  },

  list: {
    height: listHeight - tileMargin * 2,
    position: "relative",
    overflow: "hidden",
  },

  listScrollable: {
    overflow: "scroll",
  },

  lesson: {
    paddingTop: tileMargin,
  },

  lessonSearchMatch: {
    border: "3px solid " + brand.red,
    paddingTop: "10px",
    paddingBottom: "10px",
    marginTop: "5px",
  },

  lessonHeader: {},

  lessonTitle: {
    color: colors.textColor,
    fontWeight: 500,
  },

  lessonMinutes: {
    fontSize: "13px",
    fontWeight: 500,
    color: colors.fadedColor,
    marginLeft: "1em",
    minWidth: 50,
  },

  lessonDescription: {
    color: colors.minorTextColor,
    fontWeight: "300",
    marginTop: 5,
  },

  lessonButton: {
    paddingLeft: tileMargin,
    paddingRight: tileMargin,
    borderLeft: "4px solid rgba(255, 255, 255, 0.0)",

    ":focus": {
      outline: "none",
      borderLeft: "4px solid " + brand.red,
    },
    ":hover": {
      // borderLeft: "4px solid " + brand.red,
      animationName: [animHover],
      animationDuration: "0.5s",
      animationFillMode: "forwards",
      animationIterationCount: 1,
    },
  },

  lessonButtonAfterFirstHover: {
    animationName: [animUnhover],
    animationDuration: "0.3s",
    animationFillMode: "forwards",
    animationIterationCount: 1,
  },

  footer: {
    color: brand.aqua,
    textAlign: "center",
    borderTop: "1px solid #ccc",
    width: imageWidth + tileMargin * 2,
    paddingTop: tileMargin,
    paddingBottom: tileMargin,
    marginLeft: -tileMargin,
    marginTop: tileMargin,
  },

  footerSearchTotal: {
    color: brand.red,
    fontWeight: 500,
  },

  footerSearchSeparator: {
    paddingLeft: "8px",
    paddingRight: "8px",
  },

  absentFooter: {
    marginTop: tileMargin,
  },

  videoIndicator: {
    position: "absolute",
    color: brand.white,
    fontSize: "36px",
    right: 25,
    top: 34,
    zIndex: 1,
  },

  featuredLessonIndicator: {
    position: "absolute",
    width: 16,
    height: 16,
    left: 3,
    color: brand.aqua,
  },
});
