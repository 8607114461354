import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
//import { CardElement, CardNumberElement, injectStripe } from "react-stripe-elements";
import { CardElement, injectStripe } from "react-stripe-elements";
import { StyleSheet, css } from "../../utils/aphrodite";
import Spinner from "../../components/Spinner";
import { brand } from "../../styles";
import { Flex, Box } from "grid-styled";
//import Button from "../Button";
//import { subscribeConfigURI, subscribePreviewURI, subscribeURI, status } from "../../utils/uri";
import { subscribeConfigURI, subscribePreviewURI, subscribeURI } from "../../utils/uri";
import { formatCurrency } from "../../utils/format";
import dataInjector from "../../wrappers/dataInjector";
import * as gtag from "../../utils/gtag";
import { withRouter } from "react-router-dom";
//import Checkbox from "material-ui/Checkbox";
import CircularProgress from "material-ui/CircularProgress";
import Dialog from "material-ui/Dialog";
import MenuItem from "material-ui/MenuItem";
import Paper from "material-ui/Paper";
import { RadioButtonGroup, RadioButton } from "material-ui/RadioButton";
import RaisedButton from "material-ui/RaisedButton";
import SelectField from "material-ui/SelectField";
import TextField from "material-ui/TextField";
import TextFieldUnderline from "material-ui/TextField/TextFieldUnderline";
import Panel, { titleStyles } from "../Panel";

const EMAIL_REGEX = /.*@.*\..*/;
const FAQ_URL = "https://intercom.help/ehub/frequently-asked-questions";
const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please contact info@becauselearning.com for help.";

function Horizontal({ even, children }) {
  return (
    <Flex mx={-1}>
      {children.map(function(child, index) {
        return (
          <Box key={index} flex="1 1 auto" mx={1} className={css(even && styles.evenlySpaced)}>
            {child}
          </Box>
        );
      })}
    </Flex>
  );
}

function PriceIndicator({ label, price, denominator }) {
  let denominatorComponent = null;
  if (denominator) {
    denominatorComponent = <span className={css(styles.denominator)}>/{denominator}</span>;
  }

  return (
    <span>
      {label}
      <span className={css(styles.priceLabel)}>
        -
        <span className={css(styles.price)}>{price}</span>
        {denominatorComponent}
      </span>
    </span>
  );
}

function PaymentControl({ preview }) {
  if (preview.error) {
    return (
      <div className={css(styles.errorRow)}>
        {(preview.errorData && preview.errorData.error) || DEFAULT_ERROR_MESSAGE}
      </div>
    );
  }

  let paymentInfo = "";
  if (preview.data) {
    let recurringSuffix = "month";
    if (preview.data.billing_cycle_months != 1) {
      recurringSuffix = `${preview.data.billing_cycle_months} months`;
    }

    paymentInfo = `${formatCurrency(preview.data.recurring_total)} every ${recurringSuffix}. Cancel anytime.`;
  }

  return (
    <Flex justify="flex-end" align="center" mx={-1}>
      <Box className={css(styles.total)} flex="0 1 auto" mx={1}>
        {paymentInfo}
      </Box>
    </Flex>
  );
}

PaymentControl = dataInjector(
  function(props) {
    return {
      method: "post",
      url: subscribePreviewURI(),
      data: {
        plan: props.plan,
        discount: props.discount,
        quantity: props.quantity,
        expedited_shipping: props.expeditedShipping,
      },
    };
  },
  "preview",
  PaymentControl
);

function PaymentPreviewBox({ preview }) {
  try {
    let rows = [];
    if (preview.data) {
      let recurringPeriod = "1 month";
      if (preview.data.billing_cycle_months != 1) {
        recurringPeriod = `${preview.data.billing_cycle_months} months`;
      }

      rows.push({
        label: `Because Learning Subscription (${recurringPeriod})`,
        value: formatCurrency(preview.data.immediate_total_before_shipping_and_discount),
      });

      if (preview.data.immediate_discount_amount !== 0) {
        rows.push({
          label: "Discount",
          value: formatCurrency(preview.data.immediate_discount_amount),
        });
      }

      rows.push({
        label: "Shipping",
        value:
          preview.data.expedited_shipping_total === 0 ? "FREE" : formatCurrency(preview.data.expedited_shipping_total),
      });

      rows.push({
        label: "Total",
        value: formatCurrency(preview.data.immediate_total),
      });
    } else {
      rows = [];
    }

    return (
      <Paper className={css(styles.paymentPreviewPanel)}>
        <Flex direction="column">
          <Box className={css(styles.paymentPreviewPanelHeading)}>Today's Payment</Box>
          <Box>
            {/* This is tabular data. Tables are just fine. */}
            <table className={css(styles.paymentPreviewPanelTable)}>
              <tbody>
                {rows.map(({ label, value }) => (
                  <tr>
                    <td className={css(styles.paymentPreviewPanelLabel)}>{label}:</td>
                    <td className={css(styles.paymentPreviewPanelValue)}>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Flex>
      </Paper>
    );
  } catch (e) {
    console.log("failed:");
    console.trace(e);
  }
}

PaymentPreviewBox = dataInjector(
  function(props) {
    return {
      method: "post",
      url: subscribePreviewURI(),
      data: {
        plan: props.plan,
        discount: props.discount,
        quantity: props.quantity,
        expedited_shipping: props.expeditedShipping,
      },
    };
  },
  "preview",
  PaymentPreviewBox
);

class SubscribeForm extends Component {
  static contextTypes = {
    muiTheme: PropTypes.object,
  };

  state = {
    firstName: "",
    lastName: "",
    email: "",
    quantity: 1,
    expeditedShipping: false,
    address1: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    cardError: null,
    cardFieldFocused: false,
    subscribing: false,
    finished: false,
    errors: {},
    fullWidthMode: window.innerWidth && window.innerWidth > 1000,
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.onWindowResize);
  };

  componentWillUnount = () => {
    window.removeEventListener("resize", this.onWindowResize);
  };

  onWindowResize = () => {
    // TODO: optimize to not force rerender if we're not changing modes
    this.setState({
      fullWidthMode: window.innerWidth > 1000,
    });
  };

  clearError = name => {
    this.setState((state, props) => {
      let newErrors = Object.assign({}, state.errors);
      delete newErrors[name];

      return {
        errors: newErrors,
      };
    });
  };

  quantityChanged = (event, index, value) => {
    this.setState({
      quantity: value,
    });
    this.clearError("quantity");
  };

  fieldChanged = (event, value) => {
    this.setState({
      [event.target.name]: value,
    });
    this.clearError(event.target.name);
  };

  cardFieldFocused = () => {
    this.setState({
      cardFieldFocused: true,
    });
  };

  cardFieldBlurred = () => {
    this.setState({
      cardFieldFocused: false,
    });
  };

  validate = () => {
    let errors = {};

    this.validateNonEmpty("firstName", "First name", errors);
    this.validateNonEmpty("lastName", "Last name", errors);

    if (!this.state.email.match(EMAIL_REGEX)) {
      errors["email"] = "Email must be valid";
    }

    this.validateNonEmpty("address1", "Address", errors);
    this.validateNonEmpty("city", "City", errors);
    this.validateNonEmpty("state", "State", errors);

    // Note that this check (exactly 5 digits) is only valid for U.S. postal codes. This will need to be changed or not
    // checked once we support multiple countries (or better yet, we should consult with some sort of address
    // resolution service to see if this is valid - I believe ShipStation has an API for doing that)
    if (!this.state.zipCode.match(/[0-9]{5}/)) {
      errors["zipCode"] = "Zip code must be valid";
    }

    return errors;
  };

  validateNonEmpty = (name, label, errors) => {
    if (this.state[name].length === 0) {
      errors[name] = `${label} is required`;
    }
  };

  submit = async event => {
    event.preventDefault();

    let errors = this.validate();
    if (Object.getOwnPropertyNames(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({
      subscribing: true,
      errors: {},
      cardError: null,
    });

    let stripeTokenResult = await this.props.stripe.createToken({});
    if (stripeTokenResult.error) {
      this.setState({
        subscribing: false,
        cardError: stripeTokenResult.error.message,
      });
      return;
    }

    let params = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      plan: this.props.plan,
      quantity: this.state.quantity,
      discount: this.props.discount,
      expedited_shipping: this.state.expeditedShipping,
      address1: this.state.address1,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zip_code: this.state.zipCode,
      card_token: stripeTokenResult.token.id,
    };

    let promise = axios.post(subscribeURI(), params);
    try {
      let response = await promise;
    } catch (e) {
      this.setState({
        subscribing: false,
        cardError: (e.response && e.response.data && e.response.data.error) || DEFAULT_ERROR_MESSAGE,
      });
      return;
    }

    // No situations yet where this endpoint will give us anything other than {status: ok}, but we'll need to start
    // checking the response if that ever changes.

    // Log conversions to Google Tag Manager, which will take care of logging them to Google Analytics etc.
    if (process.env.REACT_APP_TRACK_CONVERSIONS) {
      gtag.event("subscriptionCreated", {
        plan: this.props.plan,
        discount: this.props.discount,
        //                immediateTotal: this.calculateImmediateTotal(),
        //                recurringTotal: this.calculateRecurringTotal(),
      });
    } else {
      console.log("conversion tracking disabled");
    }

    this.setState({
      subscribing: false,
      finished: true,
    });
  };

  cardChanged = event => {
    if (event.error) {
      this.setState({ cardError: event.error.message });
    } else {
      this.setState({ cardError: null });
    }
  };

  dismissDialog = () => {
    if (this.state.finished) {
      this.props.history.push("/");
    }
  };

  renderDialog = () => {
    let content;
    if (this.state.subscribing) {
      content = (
        <Flex justify="center">
          <Box my={4}>
            <CircularProgress size={60} thickness={5} />
          </Box>
        </Flex>
      );
    } else {
      content = (
        <Flex direction="column">
          <Box className={css(styles.dialogHeader)}>Thanks for subscribing!</Box>
          <Box mt={2} mb={1} mx={2}>
            We've sent an email to&nbsp;
            <span className={css(styles.emailConfirmation)}>{this.state.email}</span>
            &nbsp;with instructions on how to set up your account, and {"we'll"} send another one as soon as your&nbsp;
            {this.state.quantity === 1 ? "kit ships." : "kits ship."}
          </Box>
          <Box mt={2} mb={2} mx={2}>
            Visit{" "}
            <a href={FAQ_URL} target="_blank">
              our FAQ
            </a>{" "}
            if you have any questions.
          </Box>
          <Box mt={2}>
            <Flex justify="flex-end" align="center" mx={-1}>
              <Box flex="0 1 auto" mx={1}>
                <RaisedButton primary label="Got it" onClick={this.dismissDialog} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      );
    }

    return (
      <Dialog open={true} onRequestClose={this.dismissDialog} contentStyle={{ maxWidth: "700px" }}>
        {content}
      </Dialog>
    );
  };

  render() {
    if (this.props.config.error) {
      return (
        <Flex justify="center" my={3}>
          <Box className={css(styles.container)}>
            <Panel>
              <div className={css(styles.errorRow)}>
                {(this.props.config.errorData && this.props.config.errorData.error) || DEFAULT_ERROR_MESSAGE}
              </div>
            </Panel>
          </Box>
        </Flex>
      );
    }

    if (this.props.config.loading || !this.props.config.data) {
      return (
        <Flex justify="center" my={3}>
          <Box>
            <Spinner />
          </Box>
        </Flex>
      );
    }

    let quantityItems = [];
    for (let i = 1; i <= 50; i++) {
      quantityItems.push(<MenuItem key={i} primaryText={i} value={i} />);
    }

    let discountTaglineBox = null;
    let discountCalloutBox = null;

    if (this.props.config.data.tagline) {
      discountTaglineBox = (
        <Box flex="1 0 auto" className={css(styles.discountTaglineBox)} my={1}>
          {this.props.config.data.tagline}
        </Box>
      );
    }

    if (this.props.config.data.callout_box) {
      discountCalloutBox = (
        <Box>
          <Panel>
            <div
              className={css(styles.discountCalloutBox)}
              dangerouslySetInnerHTML={{ __html: this.props.config.data.callout_box }}
            />
          </Panel>
        </Box>
      );
    }

    let dialog = null;
    if (this.state.subscribing || this.state.finished) {
      dialog = this.renderDialog();
    }

    let previewProps = {
      plan: this.props.plan,
      discount: this.props.discount,
      quantity: this.state.quantity,
      expeditedShipping: this.state.expeditedShipping,
    };

    let otherBoxes = [
      <Box>
        <Panel title="Your information">
          <Flex direction="column">
            <Box>
              <Horizontal>
                <TextField
                  fullWidth
                  name="firstName"
                  floatingLabelText="First Name"
                  errorText={this.state.errors.firstName}
                  value={this.state.firstName}
                  onChange={this.fieldChanged}
                />
                <TextField
                  fullWidth
                  name="lastName"
                  floatingLabelText="Last Name"
                  errorText={this.state.errors.lastName}
                  value={this.state.lastName}
                  onChange={this.fieldChanged}
                />
              </Horizontal>
            </Box>
            <Box>
              <TextField
                fullWidth
                name="email"
                type="email"
                floatingLabelText="Email"
                errorText={this.state.errors.email}
                value={this.state.email}
                onChange={this.fieldChanged}
              />
            </Box>
            <Box>
              <SelectField
                fullWidth
                floatingLabelText="Number of kits"
                value={this.state.quantity}
                onChange={this.quantityChanged}
              >
                {quantityItems}
              </SelectField>
            </Box>
            <Box>
              <TextField
                fullWidth
                name="address1"
                floatingLabelText="Address"
                errorText={this.state.errors.address1}
                value={this.state.address1}
                onChange={this.fieldChanged}
              />
            </Box>
            <Box>
              <Flex mx={-1}>
                <Box mx={1} flex="2 2 0">
                  <TextField
                    fullWidth
                    name="city"
                    floatingLabelText="City"
                    errorText={this.state.errors.city}
                    value={this.state.city}
                    onChange={this.fieldChanged}
                  />
                </Box>
                <Box mx={1} flex="1 1 0">
                  <TextField
                    fullWidth
                    name="state"
                    floatingLabelText="State"
                    errorText={this.state.errors.state}
                    value={this.state.state}
                    onChange={this.fieldChanged}
                  />
                </Box>
                <Box mx={1} flex="1 1 0">
                  <TextField
                    fullWidth
                    name="zipCode"
                    floatingLabelText="Zip Code"
                    errorText={this.state.errors.zipCode}
                    value={this.state.zipCode}
                    onChange={this.fieldChanged}
                  />
                </Box>
              </Flex>
            </Box>
            <Box mt={2}>
              <RadioButtonGroup
                style={{ display: "flex", flexWrap: "wrap", margin: "-2px" }}
                name="expeditedShipping"
                valueSelected={this.state.expeditedShipping}
                onChange={this.fieldChanged}
              >
                <RadioButton
                  style={{ display: "inline-block", width: "auto", flexGrow: "1", margin: "2px" }}
                  label={<PriceIndicator label="Standard shipping" price="free" />}
                  value={false}
                />
                <RadioButton
                  style={{ display: "inline-block", width: "auto", flexGrow: "1", margin: "2px" }}
                  label={<PriceIndicator label="Expedited shipping" price="$7 per kit" />}
                  value={true}
                />
              </RadioButtonGroup>
            </Box>
          </Flex>
        </Panel>
      </Box>,
      <Box>
        <Panel title="Payment information">
          <Flex direction="column">
            <Box mt={2}>
              <CardElement
                id="card"
                style={{ base: { fontSize: "16px" } }}
                onChange={this.cardChanged}
                onFocus={this.cardFieldFocused}
                onBlur={this.cardFieldBlurred}
              />
            </Box>
            <Box mb={1} className={css(styles.cardUnderlineContainer)}>
              <TextFieldUnderline
                muiTheme={this.context.muiTheme}
                focus={this.state.cardFieldFocused}
                error={this.state.cardError !== null}
              />
            </Box>
            <Box className={css(styles.errorRow)}>{this.state.cardError}</Box>
            <Box>
              <PaymentControl {...previewProps} />
            </Box>
          </Flex>
        </Panel>
      </Box>,
    ];

    let sidebarUpperBoxes = [
      <Box>
        <div className={css(styles.sensorKitImageContainer)}>
          <img src="/images/sensor-kit.jpg" className={css(styles.sensorKitImage)} />
        </div>
      </Box>,
      <Box className={css(styles.whatYouGetBox)}>
        <h2>Here's What You Get:</h2>
        <dl>
          <dt>In the Mail</dt>
          <dd>
            <ul>
              <li>A Free High-Tech Sensor Kit ($150 value) to run experiments and collect real world data!</li>
            </ul>
          </dd>
          <dt>On the Web</dt>
          <dd>
            <ul>
              <li>
                Instant Access to 100+ lessons teaching standards-based subjects like math, science, engineering, and
                more
              </li>
              <li>5 New Lessons every single month</li>
              <li>Instant Access to teacher guides and student worksheets</li>
              <li>Premium Online Support</li>
            </ul>
          </dd>
        </dl>
      </Box>,
    ];

    let sidebarLowerBoxes = [
      <Box>
        <PaymentPreviewBox {...previewProps} />
      </Box>,
    ];

    let sidebar = null;
    let sidebarUpperSubstituteBoxes = null;
    let sidebarLowerSubstituteBoxes = null;
    if (this.state.fullWidthMode) {
      sidebar = (
        <Box className={css(styles.sidebarContainer)}>
          <Flex direction="column">
            {sidebarUpperBoxes}
            {sidebarLowerBoxes}
          </Flex>
        </Box>
      );
    } else {
      sidebarUpperSubstituteBoxes = sidebarUpperBoxes;
      sidebarLowerSubstituteBoxes = sidebarLowerBoxes;
    }

    return (
      <Flex justify="center" my={3} is="form" onSubmit={this.submit}>
        <Box className={css(styles.container)}>
          <Flex direction="column">
            <Box>
              <h1 className={css(styles.pageHeader)}>
                <div>Because Learning Subscription</div>
                <div>Secure checkout</div>
              </h1>
            </Box>
            {sidebarUpperSubstituteBoxes}
            {discountCalloutBox}
            {otherBoxes}
            {sidebarLowerSubstituteBoxes}
            <Box>
              <Flex justify="center">
                <Box my={2}>
                  <Flex direction="column">
                    <Box>
                      <button type="submit" className={css(styles.submitButton)}>
                        Activate Subscription
                      </button>
                    </Box>
                    <Box className={css(styles.submitButtonSubtext)}>
                      256 bit encryption security to protect your data
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
          {dialog}
        </Box>
        {sidebar}
      </Flex>
    );
  }
}

const styles = StyleSheet.create({
  pageHeader: {
    fontSize: "36px",
    fontWeight: "normal",
    color: "#555",
    margin: "0 18px 6px 18px",
  },
  container: {
    flexBasis: "500pt",
  },
  sidebarContainer: {
    flexBasis: "330px",
  },
  sensorKitImageContainer: {
    margin: "13px",
  },
  sensorKitImage: {
    width: "100%",
  },
  whatYouGetBox: {
    fontFamily: "sans-serif",
    marginLeft: "32px",
    marginRight: "17px",
    marginBottom: "10px",
    lineHeight: "1.2em",
    "->h2": {
      fontWeight: "bold",
    },
    "->dt": {
      fontSize: "0.9em",
      fontWeight: "bold",
    },
    "->dd": {
      marginLeft: "0",
    },
    "->ul": {
      fontSize: "0.9em",
      paddingLeft: "25px",
    },
  },
  panel: {
    margin: "8pt",
    padding: "16pt",
  },
  paymentPreviewPanel: {
    margin: "8pt",
    padding: "16pt",
  },
  paymentPreviewPanelHeading: {
    fontWeight: "bold",
    marginBottom: "6px",
  },
  paymentPreviewPanelTable: {
    width: "100%",
    fontSize: "0.9em",
  },
  paymentPreviewPanelLabel: {
    paddingTop: "8px",
    fontWeight: "bold",
  },
  paymentPreviewPanelValue: {
    paddingTop: "8px",
    fontWeight: "bold",
    verticalAlign: "bottom",
    paddingLeft: "12px",
    minWidth: "50px",
  },
  title: titleStyles,
  evenlySpaced: {
    flexBasis: "0",
  },
  total: {},
  priceBoxes: {
    display: "flex",
  },
  priceBox: {},
  priceLabel: {
    color: "#888",
    marginLeft: "8px",
  },
  price: {
    marginLeft: "8px",
  },
  denominator: {
    fontSize: "0.8em",
  },
  discountTaglineBox: {
    fontWeight: "700",
    color: brand.red,
    paddingTop: "1px",
  },
  discountCalloutBox: {
    "->h1": titleStyles,
    "->strong": {
      fontWeight: "700",
      color: brand.red,
    },
    "->p": {
      marginTop: "0",
      marginBottom: "1em",
    },
    "->p:last-child": {
      marginBottom: "0",
    },
  },
  cardUnderlineContainer: {
    position: "relative",
    paddingBottom: "16px",
  },
  errorRow: {
    color: "#eb1c26", // same color as stripe's error highlight
  },
  submitButton: {
    backgroundColor: brand.red,
    borderRadius: "4px",
    fontSize: "1.3em",
    fontWeight: "bold",
    color: "white",
    padding: "10px 22px",
    fontFamily: "sans-serif",
  },
  submitButtonSubtext: {
    fontSize: "0.75em",
    paddingTop: "10px",
    textAlign: "center",
  },
  dialogHeader: {
    margin: "20px",
    fontSize: "22px",
    textAlign: "center",
    color: "black",
  },
  emailConfirmation: {
    fontWeight: "700",
  },
});

export default dataInjector(
  function(props) {
    return {
      method: "get",
      url: subscribeConfigURI(),
      params: {
        plan: props.plan,
        discount: props.discount,
      },
    };
  },
  "config",
  injectStripe(withRouter(SubscribeForm))
);
