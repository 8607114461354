import React from "react";

export default ({ ...props }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ verticalAlign: "middle" }}
    {...props}
  >
    <defs />
    <g id="coding" fillRule="nonzero" fill="currentColor">
      <path
        d="M12,7 C13.656,7 15,7.672 15,8.5 C15,9.328 13.656,10 12,10 C10.344,10 9,9.328 9,8.5 C9,7.672 10.344,7 12,7 Z M6,8 C4.344,8 3,7.328 3,6.5 C3,5.672 4.344,5 6,5 C7.656,5 9,5.672 9,6.5 C9,7.328 7.656,8 6,8 Z M18,8 C16.344,8 15,7.328 15,6.5 C15,5.672 16.344,5 18,5 C19.656,5 21,5.672 21,6.5 C21,7.328 19.656,8 18,8 Z M12,6 C10.344,6 9,5.328 9,4.5 C9,3.672 10.344,3 12,3 C13.656,3 15,3.672 15,4.5 C15,5.328 13.656,6 12,6 Z M11,11.6123 L11,21.7203 L2.684,18.9483 C2.275,18.8123 2,18.4303 2,17.9993 L2,10.0003 C2,9.6783 2.154,9.3773 2.416,9.1903 C2.676,9.0003 3.01,8.9503 3.316,9.0513 L11,11.6123 Z M21.5845,9.1899 C21.8455,9.3769 22.0005,9.6789 22.0005,9.9999 L22.0005,17.9999 C22.0005,18.4299 21.7245,18.8119 21.3165,18.9489 L13.0005,21.7199 L13.0005,11.6119 L20.6835,9.0509 C20.9875,8.9509 21.3235,8.9999 21.5845,9.1899 Z"
        id="path-1"
      />
    </g>
  </svg>
);
