import React from "react";
import I18n from "../../utils/i18n";

import { StyleSheet, css } from "aphrodite/no-important";
import { Flex } from "grid-styled";

import IconArrows from "react-icons/lib/fa/arrows";
import IconCircleNotch from "react-icons/lib/fa/circle-o-notch";
import IconMagnet from "react-icons/lib/fa/magnet";
import IconFire from "react-icons/lib/fa/fire";
import IconLightbulb from "react-icons/lib/fa/lightbulb-o";
import IconEyeSlash from "react-icons/lib/fa/eye-slash";

import IconOption from "../IconOption";
import { colors } from "../../styles";
import { filters } from "../../types";

const SensorSelect = ({ tabIndex = 0, selected = null, setSensor = i => {}, ...otherProps }) => (
  <Flex align="center" {...otherProps}>
    <IconOption
      id={0}
      label={filters.sensor.accelerometer}
      onClick={e => {
        setSensor("accelerometer");
      }}
      tabIndex={tabIndex}
    >
      <IconArrows className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={1}
      label={filters.sensor.gyroscope}
      onClick={e => {
        setSensor("gyroscope");
      }}
      tabIndex={tabIndex}
    >
      <IconCircleNotch className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={2}
      label={filters.sensor.magnetometer}
      onClick={e => {
        setSensor("magnetometer");
      }}
      tabIndex={tabIndex}
    >
      <IconMagnet className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={3}
      label={filters.sensor.infrared}
      onClick={e => {
        setSensor("infrared");
      }}
      tabIndex={tabIndex}
    >
      <IconFire className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={4}
      label={filters.sensor.luminosity}
      onClick={e => {
        setSensor("luminosity");
      }}
      tabIndex={tabIndex}
    >
      <IconLightbulb className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={5}
      label={filters.sensor.ultraviolet}
      onClick={e => {
        setSensor("ultraviolet");
      }}
      tabIndex={tabIndex}
    >
      <IconEyeSlash className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={6}
      label={filters.sensor.rgb}
      onClick={e => {
        setSensor("rgb");
      }}
      tabIndex={tabIndex}
    >
      <img
        src="/rgb-sensor.svg"
        className={css(styles.icon)}
        alt={I18n.t("rgb_light_sensor", { defaultValue: "RGB Light Sensor" })}
      />
    </IconOption>
  </Flex>
);

const styles = StyleSheet.create({
  icon: {
    width: "2em",
    height: "2em",
    color: colors.iconColor,
  },

  iconBell: {
    width: "1.75em",
    height: "1.75em",
    color: colors.iconColor,
  },
});

export default SensorSelect;
