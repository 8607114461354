const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

let noticeBarHeight = 0;

function intercomEnabled() {
  return APP_ID && window.Intercom;
}

export function boot() {
  if (intercomEnabled()) {
    window.Intercom("boot", {
      app_id: APP_ID,
      vertical_padding: noticeBarHeight + 20,
    });
  }
}

export function setNoticeBarHeight(height) {
  noticeBarHeight = height;

  if (intercomEnabled()) {
    window.Intercom("update", {
      vertical_padding: noticeBarHeight + 20,
    });
  }
}

export function pageview() {
  if (intercomEnabled()) {
    window.Intercom("update");
  }
}

export function updateUserInfo(info) {
  if (intercomEnabled()) {
    window.Intercom("update", info);
  }
}

export function logout() {
  if (intercomEnabled()) {
    window.Intercom("shutdown");
    // shutdown totally disables intercom in addition to logging the current logged in user out, so we need to boot
    // again to get intercom to show back up
    boot();
  }
}
