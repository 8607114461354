import React from "react";

import { StyleSheet, css } from "aphrodite/no-important";
import { Flex } from "grid-styled";

import IconEarthSpace from "react-icons/lib/fa/globe";
import IconLifeSciences from "react-icons/lib/fa/heartbeat";
import IconChemistry from "react-icons/lib/fa/flask";
import IconPhysics from "./icons/physics";
import IconCoding from "./icons/coding";
import IconEngineering from "./icons/engineering";
import IconMath from "./icons/math";

import IconOption from "../IconOption";
import { colors } from "../../styles";
import { filters } from "../../types";

const SubjectSelect = ({ tabIndex = 0, selected = null, setSubject = i => {}, ...otherProps }) => (
  <Flex align="center" {...otherProps}>
    <IconOption
      id={1}
      label={filters.subject.space}
      onClick={e => {
        setSubject("space");
      }}
      tabIndex={tabIndex}
    >
      <IconEarthSpace className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={2}
      label={filters.subject.life}
      onClick={e => {
        setSubject("life");
      }}
      tabIndex={tabIndex}
    >
      <IconLifeSciences className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={2}
      label={filters.subject.chemistry}
      onClick={e => {
        setSubject("chemistry");
      }}
      tabIndex={tabIndex}
    >
      <IconChemistry className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={3}
      label={filters.subject.physics}
      onClick={e => {
        setSubject("physics");
      }}
      tabIndex={tabIndex}
    >
      <IconPhysics className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={4}
      label={filters.subject.coding}
      onClick={e => {
        setSubject("coding");
      }}
      tabIndex={tabIndex}
    >
      <IconCoding className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={5}
      label={filters.subject.engineering}
      onClick={e => {
        setSubject("engineering");
      }}
      tabIndex={tabIndex}
    >
      <IconEngineering className={css(styles.icon)} />
    </IconOption>

    <IconOption
      id={6}
      label={filters.subject.math}
      onClick={e => {
        setSubject("math");
      }}
      tabIndex={tabIndex}
    >
      <IconMath className={css(styles.icon)} />
    </IconOption>
  </Flex>
);

const styles = StyleSheet.create({
  icon: {
    width: "2em",
    height: "2em",
    color: colors.iconColor,
  },
});

export default SubjectSelect;
