const truncate = (str, len) => {
  if (!str) {
    return "";
  } else if (str.length <= len) {
    return str;
  } else if (str.length >= 0) {
    return str.slice(0, len) + "...";
  }
};

export default truncate;
