import React from "react";

import { StyleSheet, css } from "aphrodite/no-important";
import { Flex } from "grid-styled";

import IconChild from "react-icons/lib/fa/child";
import IconBell from "react-icons/lib/fa/bell";
import IconCap from "react-icons/lib/fa/graduation-cap";

import IconOption from "../IconOption";
import { colors } from "../../styles";
import { filters } from "../../types";

const GradeSelect = ({ tabIndex = 0, selected = {}, setGrade = i => {}, ...otherProps }) => {
  return (
    <Flex align="center" {...otherProps}>
      <IconOption
        id={0}
        selected={!!selected["elementary"]}
        label={filters.grade.elementary}
        onClick={e => {
          setGrade("elementary");
        }}
        tabIndex={tabIndex}
      >
        <IconChild className={css(styles.icon)} />
      </IconOption>

      <IconOption
        id={1}
        selected={!!selected["middle"]}
        label={filters.grade.middle}
        onClick={e => {
          setGrade("middle");
        }}
        tabIndex={tabIndex}
      >
        <IconBell className={css(styles.iconBell)} />
      </IconOption>

      <IconOption
        id={2}
        selected={!!selected["high"]}
        label={filters.grade.high}
        onClick={e => {
          setGrade("high");
        }}
        tabIndex={tabIndex}
      >
        <IconCap className={css(styles.icon)} />
      </IconOption>
    </Flex>
  );
};

const styles = StyleSheet.create({
  icon: {
    width: "2em",
    height: "2em",
    color: colors.iconColor,
  },

  iconBell: {
    width: "1.75em",
    height: "1.75em",
    color: colors.iconColor,
  },
});

export default GradeSelect;
