import React from "react";
import { SelfContainedForm, ErrorContainer } from "../forms";
import Panel from "../Panel";
//import { TextField, Button } from "../forms/material";
import { Button } from "../forms/material";
import StripeField from "../forms/stripe";
import AxiosBackend from "../forms/backends/axios";
//import { StripeProvider } from "react-stripe-elements";
import { updateCardInfoURI } from "../../utils/uri";
import { StyleSheet, css } from "../../utils/aphrodite";
import RaisedButton from "material-ui/RaisedButton";
import { withRouter } from "react-router-dom";
import { loadStatus } from "../../utils/user";

const backend = new AxiosBackend({ url: updateCardInfoURI() });

export default class CardUpdateForm extends React.Component {
  state = {
    submitted: false,
  };

  componentDidMount = async () => {
    try {
      await loadStatus();
    } catch (e) {
      this.props.history.push("/sign_in");
    }
  };

  onSubmit = () => {
    this.setState({ submitted: true });
  };

  dismiss = () => {
    this.props.history.push("/");
  };

  render() {
    let content;
    if (this.state.submitted) {
      content = [
        <div className={css(styles.confirmation)}>Your payment information has been updated.</div>,
        <div className={css(styles.buttonRow)}>
          <RaisedButton primary label="Got it" onClick={this.dismiss} />
        </div>,
      ];
    } else {
      content = [
        <div className={css(styles.stripeField)}>
          <StripeField name="card_token" />
        </div>,
        <ErrorContainer />,
        <div className={css(styles.buttonRow)}>
          <Button primary type="submit" label="Save" submittingLabel="Saving..." />
        </div>,
      ];
    }
    return (
      <SelfContainedForm
        backend={backend}
        onSubmit={this.onSubmit}
        clearErrorsOnInteraction
        className={css(styles.form)}
      >
        <Panel title="Update payment details">{content}</Panel>
      </SelfContainedForm>
    );
  }
}

CardUpdateForm = withRouter(CardUpdateForm);

const styles = StyleSheet.create({
  form: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
    "-> > *": {
      flexBasis: "500pt",
    },
  },
  stripeField: {
    margin: "24px 0 16px 0",
  },
  buttonRow: {
    display: "flex",
    justifyContent: "flex-end",
  },
  confirmation: {
    marginTop: "24px",
  },
});
