import I18n from "../utils/i18n";

export const filters = {
  grade: {
    elementary: I18n.t("elementary_school", { defaultValue: "Elementary School" }),
    middle: I18n.t("middle_school", { defaultValue: "Middle School" }),
    high: I18n.t("high_school", { defaultValue: "High School" }),
  },
  subject: {
    start: I18n.t("getting_started", { defaultValue: "Getting Started" }),
    space: I18n.t("earth_and_space", { defaultValue: "Earth and Space" }),
    life: I18n.t("life_sciences", { defaultValue: "Life Sciences" }),
    chemistry: I18n.t("chemistry", { defaultValue: "Chemistry" }),
    physics: I18n.t("physics", { defaultValue: "Physics" }),
    coding: I18n.t("coding", { defaultValue: "Coding" }),
    engineering: I18n.t("engineering", { defaultValue: "Engineering" }),
    math: I18n.t("math", { defaultValue: "Math" }),
  },
  sensor: {
    accelerometer: I18n.t("accelerometer", { defaultValue: "Accelerometer" }),
    gyroscope: I18n.t("gyroscope", { defaultValue: "Gyroscope" }),
    magnetometer: I18n.t("magnetometer", { defaultValue: "Magnetometer" }),
    infrared: I18n.t("infrared", { defaultValue: "Infrared" }),
    luminosity: I18n.t("luminosity", { defaultValue: "Luminosity" }),
    ultraviolet: I18n.t("ultraviolet", { defaultValue: "Ultraviolet" }),
    rgb: I18n.t("rgb_light", { defaultValue: "RGB Light" }),
  },
};
