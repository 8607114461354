import React from "react";

import { StyleSheet, css } from "aphrodite/no-important";
import { Flex, Box } from "grid-styled";
import IconDown from "react-feather/dist/icons/chevron-down";
import IconClose from "react-feather/dist/icons/x";
import debounce from "lodash.debounce";

import { brand, colors } from "../../styles";
import Button from "../Button";

const maximum = arr => {
  let m = Number.MIN_VALUE;
  if (arr.length === 0) {
    return 0;
  } else {
    for (let j = 0; j < arr.length; j++) {
      if (arr[j] > m) m = arr[j];
    }
    return m;
  }
};

class FilterTabs extends React.Component {
  constructor(props) {
    super(props);
    this.debouncedSetTab = debounce(props.setTab, 100, {
      leading: true,
      trailing: false,
    });
  }

  classForTabIndex(i) {
    let tabStyle;

    if (this.props.selected === null && i === 0) {
      tabStyle = styles.tabClosedFirst;
    } else if (this.props.selected === null) {
      tabStyle = styles.tabClosed;
    } else if (i === this.props.selected) {
      tabStyle = styles.tabOpen;
    } else if (i < this.props.selected) {
      tabStyle = styles.tabClosedLeft;
    } else if (i > this.props.selected) {
      tabStyle = styles.tabClosedRight;
    } else {
      throw new Error("classForTabIndex");
    }

    return css(
      styles.tabCommon,
      tabStyle,
      this.props.showLeftBorder === false && i === 0 && styles.tabWithoutLeftBorder
    );
  }

  render() {
    let tabElements = [];

    const loseFocus = i => {
      if (!!tabElements[i]) {
        if (i === this.props.selected) {
          // TODO: why isn't the tab losing focus here?
          tabElements[i].blur();
        }
      } else {
        this.props.setTab(null, this.props.selected);
      }
    };

    let maxIndex = maximum(this.props.tabIndices);

    return (
      <Flex justify="center">
        {this.props.tabs.map((tabLabel, i) => {
          return (
            <Box key={i}>
              {/* Use a `Button` here instead of `Flex` because we need to use `ref` */}
              <Button
                className={this.classForTabIndex(i)}
                onFocus={() => {
                  this.debouncedSetTab(i);
                }}
                onClick={() => {
                  this.debouncedSetTab(i);
                }}
                ref={element => {
                  tabElements[i] = element;
                }}
                tabIndex={this.props.tabIndices[i]}
              >
                <Box className={css(styles.label, this.props.underlined === i && styles.labelWithUnderline)}>
                  {tabLabel}
                </Box>
                <Box
                  className={css(styles.icon)}
                  onClick={
                    i === this.props.selected &&
                    (e => {
                      e.stopPropagation();
                      loseFocus(i);
                    })
                  }
                >
                  {i === this.props.selected ? <IconClose /> : <IconDown />}
                </Box>
              </Button>
            </Box>
          );
        })}

        {/* Create a final "button" is a tab stop, used to remove
                * sticky tab highlighting for accessibility.
                */}
        <Box key={maxIndex + 1} className={css(styles.invisibleTabContainer)}>
          <Button
            className={css(styles.invisibleTab)}
            onFocus={() => {
              this.props.unsetTab();
            }}
            tabIndex={maxIndex + 1}
          />
        </Box>
      </Flex>
    );
  }
}

const styles = StyleSheet.create({
  tabCommon: {
    display: "flex",
    height: 40,
    lineHeight: "40px",
    marginTop: "15px",
    border: 0,
    padding: "0px 8px 0px 16px",

    color: brand.dark,
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "uppercase",

    backgroundColor: "inherit",

    // Remove default outline when tab has focus
    ":focus": {
      outline: "none",
      color: colors.buttonColor,
    },

    "@media (max-width: 410px)": {
      fontSize: "11px",
    },
  },

  tabOpen: {
    borderLeft: "2px solid " + brand.red + " !important",
    borderRight: "2px solid " + brand.red + " !important",
    borderTop: "2px solid " + brand.red + " !important",
    paddingBottom: 1,
    paddingLeft: 18,
    height: 39,
    lineHeight: "39px",
  },

  tabClosedFirst: {
    borderTop: "1px solid " + colors.fadedColor + " !important",
    borderRight: "1px solid " + colors.fadedColor + " !important",
    borderLeft: "1px solid " + colors.fadedColor + " !important",
    borderBottom: "1px solid " + colors.fadedColor + " !important",
  },

  tabClosed: {
    borderTop: "1px solid " + colors.fadedColor + " !important",
    borderRight: "1px solid " + colors.fadedColor + " !important",
    borderBottom: "1px solid " + colors.fadedColor + " !important",
  },

  tabClosedLeft: {
    borderTop: "1px solid " + colors.fadedColor + " !important",
    borderBottom: "2px solid " + brand.red + " !important",
    borderLeft: "1px solid " + colors.fadedColor + " !important",
    paddingTop: 1,
    height: 38,
    lineHeight: "38px",
  },

  tabClosedRight: {
    borderTop: "1px solid " + colors.fadedColor + " !important",
    borderRight: "1px solid " + colors.fadedColor + " !important",
    borderBottom: "2px solid " + brand.red + " !important",
    paddingTop: 1,
    height: 38,
    lineHeight: "38px",
  },

  tabWithoutLeftBorder: {
    borderLeft: 0,
    paddingLeft: 20,
    "@media (max-width: 803px)": {
      borderLeft: "1px solid " + colors.fadedColor + " !important",
      paddingLeft: 16,
    },
  },

  label: {
    marginRight: 28,

    "@media (max-width: 410px)": {
      marginRight: 10,
    },
  },

  labelWithUnderline: {
    borderBottom: "2px solid " + brand.red + " !important",
  },

  icon: {
    paddingTop: 8,
    "@media (max-width: 410px)": {
      width: 19,
      height: 19,
    },
  },

  invisibleTabContainer: {
    width: "1px",
  },

  invisibleTab: {
    outline: "none",
  },
});

FilterTabs.defaultProps = {
  tabs: [],
  tabIndices: [],
  selected: null,
  underlined: null,
  setTab: i => {},
  unsetTab: () => {},
  showLeftBorder: true,
};

export default FilterTabs;
