export function event(name, info = {}) {
  let event = Object.assign({ event: name }, info);

  if (window.dataLayer) {
    window.dataLayer.push(event);
  } else {
    console.log("Google Tag Manager doesn't appear to be available, dropping the following event:");
    console.log(event);
  }
}
