import URI from "urijs";

export const EHUB_BASE_URL = process.env.REACT_APP_EHUB_URI || "http://localhost:3000";

export const ARDUSAT_USER = 2032;

export const status = async response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    // const error = await response.json()
    throw Error(await response.text());
  }
};
export const uri = (resource, params = {}) => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(resource).addQuery(params);
};

export const searchExperimentsURI = ({ query, user = ARDUSAT_USER, pagination: { limit = 12, page = 1 } = {} }) => {
  let url = uri(["api", "v2", "experiments"], { limit, page });
  if (query !== null) {
    url = url.addSearch({ "search[query]": [query] });
  }
  if (user !== null) {
    url = url.addSearch({ "search[user]": [user] });
  }

  return url.toString();
};

export const searchUnitsURI = ({
  query,
  grade,
  subject,
  sensor,
  featured,
  pagination: { limit = 12, page = 1 } = {},
}) => {
  let url = uri(["api", "v2", "units"], { limit, page });
  if (query !== null) {
    url = url.addSearch({ query: [query] });
  }
  if (grade !== null) {
    url = url.addSearch({ grade: [grade] });
  }
  if (subject !== null) {
    url = url.addSearch({ subject: [subject] });
  }
  if (sensor !== null) {
    url = url.addSearch({ sensor: [sensor] });
  }
  if (featured !== null) {
    url = url.addSearch({ featured: [featured] });
  }

  return url.toString();
};

export const createUnitURI = () => {
  return uri(["api", "v2", "units"]).toString();
};

export const getUnitURI = ({ unitId }) => {
  return uri(["api", "v2", "units", unitId]).toString();
};

export const signInURI = () => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(["users", "sign_in.json"]).toString();
};

export const currentUserURI = () => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(["api", "v2", "users", "current.json"]).toString();
};

export const signOutURI = () => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(["users", "sign_out.json"]).toString();
};

export const userProfileURI = userId => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(["users", userId.toString(), "experiments"]).toString();
};

export const subscribeConfigURI = () => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(["api", "v2", "subscriptions", "config"]).toString();
};

export const subscribePreviewURI = () => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(["api", "v2", "subscriptions", "preview"]).toString();
};

export const subscribeURI = () => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(["api", "v2", "subscriptions", "create"]).toString();
};

export const updateCardInfoURI = () => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(["api", "v2", "subscriptions", "update_card_info"]).toString();
};

export const resetPasswordURI = () => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(["api", "v2", "password", "reset"]).toString();
};

export const forgotPasswordURI = () => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(["api", "v2", "password", "reset", "request"]).toString();
};

export const oauthSignInURI = (provider) => {
  const uri = new URI(EHUB_BASE_URL);
  return uri.segment(["users", "auth", provider]).query({user_return_to: '/omniauth_callbacks/json'}).toString();
}
