import React from "react";
import { Flex, Box } from "grid-styled";
import { StyleSheet, css } from "../../utils/aphrodite";
import Paper from "material-ui/Paper";

export default function Panel({ title, rightSide, childContainerClassName = "", children }) {
  let upperBox = null;
  if (title || rightSide) {
    let titleBox = null;
    if (title) {
      titleBox = (
        <Box className={css(styles.title)} mx={2}>
          {title}
        </Box>
      );
    }

    let rightSideBox = null;
    if (rightSide) {
      rightSideBox = <Box mx={2}>{rightSide}</Box>;
    }

    upperBox = (
      <Box>
        <Flex direction="row" align="baseline" justify="space-between" mx={-2}>
          {titleBox}
          {rightSideBox}
        </Flex>
      </Box>
    );
  }

  return (
    <Paper className={css(styles.panel)}>
      <Flex direction="column">
        {upperBox}
        <Box>{children}</Box>
      </Flex>
    </Paper>
  );
}

export const titleStyles = {
  fontWeight: "500",
  fontSize: "23px",
};

const styles = StyleSheet.create({
  panel: {
    margin: "8pt",
    padding: "16pt",
  },
  title: titleStyles,
});
