import React from "react";
import PropTypes from "prop-types";
import { makeFormField } from "../forms";

//import { CardElement, CardNumberElement, Elements, injectStripe } from "react-stripe-elements";
import { CardElement, Elements, injectStripe } from "react-stripe-elements";
import TextFieldUnderline from "material-ui/TextField/TextFieldUnderline";
import { StyleSheet, css } from "../../utils/aphrodite";

export default class StripeField extends React.Component {
  render() {
    return (
      <Elements>
        <MiddleStripeField {...this.props} />
      </Elements>
    );
  }
}

class MiddleStripeField extends React.Component {
  render() {
    return <InnerStripeField {...this.props} />;
  }
}

MiddleStripeField = injectStripe(MiddleStripeField);

class InnerStripeField extends React.Component {
  static contextTypes = {
    muiTheme: PropTypes.object,
  };

  state = {
    cardFieldFocused: false,
    error: null,
  };

  cardFieldFocused = () => {
    this.setState({
      cardFieldFocused: true,
    });
    this.props.fieldProps.onFocus();
  };

  cardFieldBlurred = () => {
    this.setState({
      cardFieldFocused: false,
    });
  };

  cardFieldChanged = event => {
    // Change events from CardElement happen at weird times. Fortunately one of those times is when the field is
    // changed, which is close enough to the spirit of onInteraction for this to work for us.
    this.props.fieldProps.onInteraction();

    // Also, Stripe handles real time validation for us, so we clear errors whenever we get a change from Stripe to get
    // the previously submitted error out of the way of whatever Stripe's telling us now.
    this.props.fieldProps.clearFieldError();

    if (event.error) {
      this.setState({ error: event.error.message });
    } else {
      this.setState({ error: null });
    }
  };

  render() {
    let fieldProps = this.props.fieldProps;
    let error = this.state.error || fieldProps.error;

    return (
      <div className={css(styles.cardContainer)}>
        <div>
          <CardElement
            id="card"
            style={{ base: { fontSize: "16px", color: fieldProps.disabled ? "#999" : "black" } }}
            onFocus={this.cardFieldFocused}
            onBlur={this.cardFieldBlurred}
            onChange={this.cardFieldChanged}
          />
        </div>
        <div className={css(styles.cardUnderlineContainer)}>
          <TextFieldUnderline
            muiTheme={this.context.muiTheme}
            focus={this.state.cardFieldFocused}
            disabled={fieldProps.disabled}
            error={!!error}
          />
        </div>
        <div className={css(styles.cardError)}>{error}</div>
      </div>
    );
  }
}

InnerStripeField = makeFormField(
  {
    presubmit: async function(value, props) {
      let result = await props.stripe.createToken({});

      if (result.error) {
        return {
          error: result.error.message,
        };
      }

      return {
        value: result.token.id,
      };
    },
  },
  InnerStripeField
);

const styles = StyleSheet.create({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
  },
  cardUnderlineContainer: {
    position: "relative",
    paddingBottom: "16px",
  },
  cardError: {
    color: "#eb1c26", // same color as stripe's error highlight
  },
});
