import React from "react";

export default ({ ...props }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 116 202"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ verticalAlign: "middle" }}
    {...props}
  >
    <defs />
    <g id="coding" fillRule="nonzero" fill="currentColor">
      <path d="M82.548,55.88c0,22.91 -15.68,54.21 -19.9,77.2l-7.71,0c-4.16,-22.63 -19.32,-53.65 -19.86,-76.08c-0.34,-13.13 9.82,-24.4 22.94,-24.83c0.257,-0.008 0.514,-0.012 0.77,-0.012c13.021,0 23.74,10.702 23.76,23.722l0,0Zm-11.94,103c0,-6.484 -5.335,-11.82 -11.82,-11.82c-6.484,0 -11.82,5.336 -11.82,11.82c0,6.484 5.336,11.82 11.82,11.82c6.477,0.006 11.814,-5.313 11.83,-11.79l-0.01,-0.03Z" />
      <path d="M0,0.157l115.078,-0.157l0,201.81l-115.078,0l0,-201.653Zm103.078,189.693l0,-177.85l-91.11,0l0,177.85l91.11,0Z" />
    </g>
  </svg>
);
