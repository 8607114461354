import React from "react";

import { StyleSheet, css } from "aphrodite/no-important";
import { colors } from "../../styles";
import Button from "../Button";
import { Flex, Box } from "grid-styled";

export default class MoreButton extends React.Component {
  onClick = event => {
    event.preventDefault();

    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <Flex justify="center">
        <Box>
          <Button className={css(styles.button)} onClick={this.onClick}>
            {this.props.text}
          </Button>
        </Box>
      </Flex>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    font: "1.0em sans-serif",
    height: ".6em",
    marginBottom: "2.5em",
    display: "block",
    color: colors.textColor,
  },
});
