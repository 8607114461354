import { extendObservable } from "mobx";
import { signIn, signOut, loadStatus } from "../utils/user";

class CurrentUser {
  loadStatus = async () => {
    try {
      const user = await loadStatus();
      this.setUserSignedIn(user);
    } catch (e) {
      this.setUserSignedOut();
    }
  };

  signIn = async (email, password) => {
    const user = await signIn(email, password);
    this.setUserSignedIn(user);
  };

  signOut = async () => {
    const didSignOut = await signOut();

    if (didSignOut) {
      this.setUserSignedOut();
    } else {
      console.error("Unable to sign out");
    }
  };

  setUserSignedIn = user => {
    this.user = user;
    window.Raven.setUser({ id: user.id, email: user.email });
  };

  setUserSignedOut = () => {
    this.user = null;
    window.Raven.setUser();
  };

  constructor() {
    extendObservable(this, {
      user: null,
    });
  }
}

export default new CurrentUser();
