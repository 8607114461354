import I18n from "../utils/i18n";

import { oauthSignInURI, EHUB_BASE_URL } from './uri';

import EventEmitter from 'eventemitter3';

const WINDOW_TITLES = {
  google_oauth2: () => I18n.t('sign_in_with_google', { defaultValue: 'Sign In with Google' }),
  classlink: () => I18n.t('sign_in_with_classlink', { defaultValue: 'Sign In with ClassLink' })
};

let oauthWindow = null;
let pollTimer = null;
let messageListener = null;
let eventEmitter = new EventEmitter();

export function on(name, listener) {
  eventEmitter.on(name, listener);
}

export function off(name, listener) {
  eventEmitter.off(name, listener);
}

export function hide() {
  if (oauthWindow) {
    oauthWindow.close();
    oauthWindow = null;
    clearInterval(pollTimer);
    pollTimer = null;
    window.removeEventListener('message', messageListener);
  }
}

export function show(provider) {
  hide();

  let url = oauthSignInURI(provider);
  let windowFeatures = 'menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,width=700,height=700';

  messageListener = event => {
    if (event.origin === EHUB_BASE_URL) {
      if (event.data.type === 'userLoggedIn') {
        hide();
        eventEmitter.emit('succeeded', event.data.user, event.data.redirect);
      } else if (event.data.type === 'userLoginError') {
        hide();
        eventEmitter.emit('failed', event.data.error);
      }
    } else {
      console.log('ignoring message with origin', event.origin);
    }
  }
  window.addEventListener('message', messageListener);

  oauthWindow = window.open(url, WINDOW_TITLES[provider](), windowFeatures);

  pollTimer = setInterval(() => {
    if (oauthWindow && oauthWindow.closed) {
      hide();
      eventEmitter.emit('canceled');
    }
  }, 250);
}
