import React from "react";

export default ({ ...props }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ verticalAlign: "middle" }}
    {...props}
  >
    <defs />
    <g id="math" fillRule="nonzero" fill="currentColor">
      <path
        d="M14,0 L14,2 L10,2 L10,0 L14,0 Z M19,20 L19,18 L21,18 L21,24 L19,24 L19,22 L5,22 L5,24 L3,24 L3,18 L5,18 L5,20 L19,20 Z M23,3 C23.552,3 24,3.448 24,4 L24,16 C24,16.552 23.552,17 23,17 L1,17 C0.448,17 0,16.552 0,16 L0,4 C0,3.448 0.448,3 1,3 L23,3 Z M20,13 L20,7.75 C20,7.335 19.665,7 19.25,7 L17,7 L17,8.5 L18.5,8.5 L18.5,13 L20,13 Z M16,11 L16,9 L14,9 L14,7 L12,7 L12,9 L10,9 L10,11 L12,11 L12,13 L14,13 L14,11 L16,11 Z M8.5,10 L8.5,7.75 C8.5,7.335 8.165,7 7.75,7 L4,7 L4,8.5 L7,8.5 L7,9.25 L4.75,9.25 C4.335,9.25 4,9.585 4,10 L4,12.25 C4,12.665 4.335,13 4.75,13 L8.5,13 L8.5,11.5 L5.5,11.5 L5.5,10.75 L7.75,10.75 C8.165,10.75 8.5,10.415 8.5,10 Z"
        id="path-1"
      />
    </g>
  </svg>
);
