import numeral from "numeral";

export function formatCurrency(value, { integer = true, alwaysShowDecimal = false, neverShowDecimal = false } = {}) {
  if (integer) {
    value = value / 100;
  }

  if (alwaysShowDecimal) {
    return numeral(value).format("$0,0.00");
  } else if (neverShowDecimal) {
    return numeral(value).format("$0,0");
  } else {
    return numeral(value).format("$0,0[.]00");
  }
}
