import { StyleSheet } from "aphrodite/no-important";
import { brand } from "../../styles";

import { defaultWidth, defaultHeight } from "./defaults";

const absoluteFullSize = {
  bottom: 0,
  top: 0,
  left: 0,
  right: 0,
  position: "absolute",
};

export default StyleSheet.create({
  outerButton: {
    display: "flex",
  },

  container: {
    position: "relative",
    width: defaultWidth,
    height: defaultHeight,
    overflow: "hidden",
  },

  image: {
    maxWidth: defaultWidth,
  },

  overlay: {
    ...absoluteFullSize,
    zIndex: 1,
    background: "linear-gradient( 0deg, rgba(50,50,50,1.0) 0%, rgba(50,50,50,0.9) 15%, rgba(100, 100, 100, 0.0) 80%)",
  },

  title: {
    ...absoluteFullSize,
    zIndex: 2,
    color: "white",
    padding: "5%",
  },

  titleButton: {
    letterSpacing: "1px",
    fontWeight: 600,
    fontSize: "18px",
  },

  input: {
    color: brand.dark,
    border: "2px solid " + brand.white + " !important",
  },

  canEditText: {
    cursor: "pointer",
    ":hover": {
      outline: "2px " + brand.white + " dashed",
      outlineOffset: "5px",
    },
  },
});
