import React from "react";
import axios from "axios";

export default function(decider, propName, WrappedComponent) {
  return class extends React.Component {
    state = {
      loading: false,
      data: null,
      error: null,
      errorData: null,
      requestCancelTokenSource: null,
    };
    lastRequestConfig = {};

    componentDidMount = () => {
      this.componentWillReceiveProps(this.props);
    };

    componentWillReceiveProps = newProps => {
      let newRequestConfig = decider(newProps);
      // Quick and dirty config comparison. Could be done better.
      if (JSON.stringify(newRequestConfig) === JSON.stringify(this.lastRequestConfig)) {
        return;
      }

      this.lastRequestConfig = newRequestConfig;

      this.setState(oldState => {
        let newState = {};

        if (oldState.requestCancelTokenSource) {
          oldState.requestCancelTokenSource.cancel();
          newState.requestCancelTokenSource = null;
        }

        this.startRequest(oldState, newState, newProps, newRequestConfig);

        return newState;
      });
    };

    componentWillUnmount = () => {
      if (this.state.requestCancelTokenSource) {
        this.state.requestCancelTokenSource.cancel();
      }
    };

    startRequest = (oldState, newState, newProps, newRequestConfig) => {
      newState.loading = true;

      let cancelTokenSource = axios.CancelToken.source();
      newState.requestCancelTokenSource = cancelTokenSource;

      let requestConfig = Object.assign({}, newRequestConfig, {
        cancelToken: cancelTokenSource.token,
      });

      axios(requestConfig)
        .then(response => {
          this.setState(oldState => {
            return {
              loading: false,
              data: response.data,
              error: null,
              errorData: null,
              requestCancelTokenSource: null,
            };
          });
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            this.setState(oldState => {
              return {
                loading: false,
                error: error,
                errorData: error.response && error.response.data,
                requestCancelTokenSource: null,
              };
            });
          }
        });
    };

    render() {
      let props = {
        [propName]: {
          loading: this.state.loading,
          data: this.state.data,
          error: this.state.error,
          errorData: this.state.errorData,
        },
      };

      return <WrappedComponent {...props} {...this.props} />;
    }
  };
}
