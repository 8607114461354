import { Record, Map, Set, List, fromJS } from "immutable";
import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "../../utils/aphrodite";
import { createWarning } from "../../utils/warning";

const FIELD_NAME_CHANGE_WARNING = createWarning("Form fields do not support changing their names on the fly.");

export class FormErrors extends Record({
  fields: Map(),
  other: List(),
}) {}

export class FormState extends Record({
  values: Map(),
  errors: new FormErrors(),
  submitting: false,
  submitted: false,
}) {}

export class FormProps extends Record({
  disabled: false,
}) {}

export class FieldProps extends Record({
  value: null,
  error: null,
  onChange: null,
  onUpdate: null,
  onInteraction: null,
  clearFieldError: null,
  onFocus: null,
  disabled: false,
  submitting: false,
  submitted: false,
}) {}

export class ControlProps extends Record({
  disabled: false,
  submitting: false,
  submitted: false,
}) {}

export class Form extends React.Component {
  static propTypes = {
    formState: PropTypes.instanceOf(FormState),
    onFormStateUpdate: PropTypes.func,
    disabled: PropTypes.bool,
    backend: PropTypes.any,
    clearErrorsOnInteraction: PropTypes.bool,
    clearErrorsOnFocus: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.fields = new Set();
    this.formControls = new Set();
    this.formState = props.formState;
    this.formProps = this.calculateFormProps(props);

    // formState.submitting stores whether the form should be visually represented as submitting. Since it's possible
    // we might not see that change until after a second submission attempt is made, we track a more up-to-date version
    // in this.submitting that's used purely to prevent duplicate submission attempts from being made.
    this.submitting = false;
    this.submitted = false;
  }

  registerField = field => {
    this.fields = this.fields.add(field);
  };

  unregisterField = field => {
    this.fields = this.fields.delete(field);
  };

  registerFormControl = formControl => {
    this.formControls = this.formControls.add(formControl);
  };

  unregisterFormControl = formControl => {
    this.formControls = this.formControls.delete(formControl);
  };

  requestFormStateUpdate = (updater, callback) => {
    this.props.onFormStateUpdate(updater, callback);
  };

  onFieldInteraction = (field, clearErrorsOnInteraction) => {
    if (this.props.clearErrorsOnInteraction || clearErrorsOnInteraction) {
      this.clearFieldError(field);
    }
  };

  onFieldFocus = (field, clearErrorsOnFocus) => {
    if (this.props.clearErrorsOnFocus || clearErrorsOnFocus) {
      this.clearFieldError(field);
    }
  };

  clearFieldError = field => {
    this.requestFormStateUpdate(formState => {
      return formState.deleteIn(["errors", "fields", field.name]);
    });
  };

  requestValidate = async () => {
    // ... finish up out-of-submit validation ...
  };

  requestSubmit = async () => {
    if (this.submitting || this.submitted) {
      return;
    }

    this.submitting = true;
    this.requestFormStateUpdate(formState => {
      return formState.set("submitting", true).set("errors", new FormErrors());
    });

    let submission = new Map();
    let errors = new Map();

    for (let field of this.fields) {
      let value = field.value;

      if (field.presubmit) {
        let result = (await field.presubmit(value, field.props)) || {};
        if (result.value !== undefined) {
          value = result.value;
        }
        if (result.error !== undefined) {
          errors = errors.set(field.name, result.error);
        }
      }

      submission = submission.set(field.name, value);
    }

    if (errors.size > 0) {
      console.log("cancel submit");
      this.submitting = false;
      this.requestFormStateUpdate(formState => {
        return formState.set("submitting", false).set("errors", new FormErrors({ fields: errors }));
      });
      return;
    }

    let result = (await this.props.backend.submit(submission)) || {};

    if (result.errors) {
      this.submitting = false;
      this.requestFormStateUpdate(formState => {
        return formState.set("submitting", false).set("errors", new FormErrors(fromJS(result.errors)));
      });
      return;
    }

    this.submitting = false;
    this.submitted = true;
    this.requestFormStateUpdate(formState => {
      return formState.set("submitting", false).set("submitted", true);
    });

    if (this.props.onSubmit) {
      this.props.onSubmit(submission, result.data);
    }
  };

  formSubmitted = event => {
    event.preventDefault();
    this.requestSubmit();
  };

  calculateFormProps = props => {
    return new FormProps({
      disabled: props.disabled,
    });
  };

  componentWillReceiveProps = (props, context) => {
    let newFormProps = this.calculateFormProps(props);

    if (props.formState != this.formState || newFormProps != this.formProps) {
      this.formState = props.formState;
      this.formProps = newFormProps;

      for (let field of this.fields) {
        field.formUpdated();
      }

      for (let formControl of this.formControls) {
        formControl.formUpdated();
      }
    }
  };

  static childContextTypes = {
    form: PropTypes.any,
  };

  getChildContext() {
    return {
      form: this,
    };
  }

  createSubmissionForValidate = async () => {
    let submission = new Map();

    for (let field of this.fields) {
      submission = await field.prepareToValidate(submission);
    }

    return submission;
  };

  createSubmissionForSubmit = async () => {
    let submission = new Map();

    for (let field of this.fields) {
      submission = await field.prepareToSubmit(submission);
    }

    return submission;
  };

  render() {
    let { formState, onFormStateUpdate, onSubmit, ...otherProps } = this.props;
    return <form onSubmit={this.formSubmitted} {...otherProps} />;
  }
}

export class SelfContainedForm extends React.Component {
  state = {
    formState: new FormState(),
  };

  handleFormStateUpdate = (updater, callback) => {
    this.setState(state => {
      return {
        formState: updater(state.formState),
      };
    }, callback);
  };

  render() {
    return <Form formState={this.state.formState} onFormStateUpdate={this.handleFormStateUpdate} {...this.props} />;
  }
}

export function makeFormField(
  {
    presubmit = null,
    prevalidate = null,
    defaultValue = null, // or undefined?
  } = {},
  WrappedComponent
) {
  return class extends React.Component {
    static contextTypes = {
      form: PropTypes.any,
    };

    constructor(props, context) {
      super(props, context);
      this.name = this.props.name;
      this.prevalidate = prevalidate;
      this.presubmit = presubmit;
    }

    get formState() {
      return this.context.form.formState;
    }

    get formProps() {
      return this.context.form.formProps;
    }

    get fieldProps() {
      return new FieldProps({
        value: this.value,
        error: this.error,
        onChange: this.requestChange,
        onUpdate: this.requestUpdate,
        onInteraction: this.onInteraction,
        clearFieldError: this.clearFieldError,
        onFocus: this.onFocus,
        disabled:
          this.props.disabled || this.formProps.disabled || this.formState.submitting || this.formState.submitted,
        submitting: this.formState.submitting,
        submitted: this.formState.submitted,
      });
    }

    get value() {
      return this.formState.values.get(this.name, defaultValue);
    }

    get error() {
      return this.formState.errors.fields.get(this.name);
    }

    componentDidMount = () => {
      this.context.form.registerField(this);
    };

    componentWillUnmount = () => {
      this.context.form.unregisterField(this);
    };

    formUpdated = () => {
      // TODO: optimize here or in Form.componentWillReceiveProps to not re-render if things haven't changed
      this.forceUpdate();
    };

    componentWillReceiveProps = props => {
      if (props.name != this.name) {
        FIELD_NAME_CHANGE_WARNING();
      }
    };

    requestChange = newValue => {
      this.onInteraction();
      this.requestUpdate(oldValue => {
        return newValue;
      });
    };

    requestUpdate = (updater, callback) => {
      this.onInteraction();
      this.context.form.requestFormStateUpdate(oldState => {
        return oldState.updateIn(["values", this.props.name], updater);
      }, callback);
    };

    onInteraction = () => {
      this.context.form.onFieldInteraction(this, this.props.clearErrorsOnInteraction);
    };

    onFocus = () => {
      this.context.form.onFieldFocus(this, this.props.clearErrorsOnFocus);
    };

    clearFieldError = () => {
      this.context.form.clearFieldError(this);
    };

    render() {
      let { name, disabled, ...otherProps } = this.props;
      return <WrappedComponent {...otherProps} name={name} fieldProps={this.fieldProps} />;
    }
  };
}

export function makeFormControl(WrappedComponent) {
  return class extends React.Component {
    static contextTypes = {
      form: PropTypes.any,
    };
    componentDidMount = () => {
      this.context.form.registerFormControl(this);
    };

    componentWillUnmount = () => {
      this.context.form.unregisterFormControl(this);
    };

    get formState() {
      return this.context.form.formState;
    }

    get formProps() {
      return this.context.form.formProps;
    }

    formUpdated = () => {
      this.forceUpdate();
    };

    get controlProps() {
      return new ControlProps({
        disabled:
          this.props.disabled || this.formProps.disabled || this.formState.submitting || this.formState.submitted,
        submitting: this.formState.submitting,
        submitted: this.formState.submitted,
      });
    }

    render() {
      let { disabled, ...otherProps } = this.props;
      return <WrappedComponent {...otherProps} controlProps={this.controlProps} />;
    }
  };
}

export class ErrorContainer extends React.Component {
  static contextTypes = {
    form: PropTypes.any,
  };

  componentDidMount = () => {
    this.context.form.registerFormControl(this);
  };

  componentWillUnmount = () => {
    this.context.form.unregisterFormControl(this);
  };

  formUpdated = () => {
    this.forceUpdate();
  };

  render() {
    let { is = "div", childrenAre = "div", className, ...otherProps } = this.props;
    let Component = is;
    let ChildComponent = childrenAre;

    return (
      <Component className={className || css(styles.errorContainer)} {...otherProps}>
        {this.context.form.formState.errors.other.map((error, index) => {
          return <ChildComponent key={index}>{error}</ChildComponent>;
        })}
      </Component>
    );
  }
}

const styles = StyleSheet.create({
  errorContainer: {
    color: "#eb1c26", // same color as stripe's error highlight
  },
});
