import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import I18n from "../../utils/i18n";

import { StyleSheet, css } from "aphrodite/no-important";
import { Flex, Box } from "grid-styled";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";

import * as oauth from '../../utils/oauth';
import OAuthButton from '../../components/OAuthButton';

import { brand } from "../../styles";

class SignIn extends Component {
  state = {
    email: "",
    password: "",
  };

  constructor(props) {
    super(props);

    this.requestClose = () => {
      this.props.history.push("/");
    };

    this.setEmail = email => {
      this.setState({ email: email });
    };

    this.setPassword = password => {
      this.setState({ password: password });
    };
  }

  componentDidMount() {
    oauth.on('succeeded', this.oauthSignInSucceeded);
    oauth.on('failed', this.oauthSignInFailed);
  }

  componentWillUnmount() {
    oauth.off('succeeded', this.oauthSignInSucceeded);
    oauth.off('failed', this.oauthSignInFailed);
  }

  oauthSignInSucceeded = (user, redirect) => {
    this.props.onOAuthSignIn(user, redirect);
  }

  oauthSignInFailed = error => {
    this.props.onOAuthSignInFailed(error);
  }

  submit = event => {
    event.preventDefault();
    this.props.onSignIn(this.state.email, this.state.password);
  };

  signInWithGoogle = event => {
    event.preventDefault();
    oauth.show('google_oauth2');
  }

  signInWithClasslink = event => {
    event.preventDefault();
    oauth.show('classlink');
  }

  navToForgotPassword = event => {
    event.preventDefault();
    const { history } = this.props;
    history.push('/forgot_password');
  }

  render() {
    return (
      <Dialog open={true} onRequestClose={this.requestClose} contentStyle={{ maxWidth: "520px" }}>
        <Flex direction="column" is="form" onSubmit={this.submit}>
          <Box className={css(styles.header)}>{I18n.t("sign_in", { defaultValue: "Sign In" })}</Box>
          <Box>
            <Flex justify="space-evenly">
              <Box>
                <OAuthButton label={I18n.t('sign_in_with_google_button', { defaultValue: 'Sign in with Google' })} provider="google" onClick={this.signInWithGoogle}/>
              </Box>
              <Box>
                <OAuthButton label={I18n.t('sign_in_with_classlink_button', { defaultValue: 'Sign in with ClassLink' })} provider="classlink" onClick={this.signInWithClasslink}/>
              </Box>
            </Flex>
          </Box>
          {this.props.errorMessage !== "" && <Box className={css(styles.error)}>{this.props.errorMessage}</Box>}
          <Box className={css(styles.email)}>
            <TextField
              fullWidth
              floatingLabelText={I18n.t("enter_your_email", { defaultValue: "Enter your email" })}
              type="text"
              value={this.state.email}
              onChange={(e, newEmail) => this.setEmail(newEmail)}
            />
          </Box>
          <Box className={css(styles.password)}>
            <TextField
              fullWidth
              floatingLabelText={I18n.t("enter_a_password", { defaultValue: "Enter a password" })}
              type="password"
              value={this.state.password}
              onChange={(e, newPassword) => this.setPassword(newPassword)}
            />
          </Box>
          <Box className={css(styles.buttonRow)}>
            <Flex justify="flex-end" align="baseline" mx={-1}>
              <Box mx={2} className={css(styles.forgot)}>
                <a href="#" onClick={ this.navToForgotPassword }>{I18n.t("forgot_password", { defaultValue: "Forgot Password?" })}</a>
              </Box>
              <Box mx={1}>
                <FlatButton label={I18n.t("cancel", { defaultValue: "Cancel" })} onClick={this.requestClose} />
              </Box>
              <Box mx={1}>
                <RaisedButton primary type="submit" label={I18n.t("sign_in", { defaultValue: "Sign In" })} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Dialog>
    );
  }
}

SignIn.propTypes = {
  errorMessage: PropTypes.string,
  onSignIn: PropTypes.func,
};

SignIn.defaultProps = {
  errorMessage: "",
  onSignIn: (email, password) => {},
};

const styles = StyleSheet.create({
  header: {
    marginBottom: '20px'
  },
  email: {},
  password: {},
  buttonRow: {
    marginTop: '16px'
  },
  forgot: {
    fontSize: "12px",
  },
  error: {
    color: brand.red,
    paddingTop: 30,
  },
});

export default withRouter(SignIn);
