import { StyleSheet } from "aphrodite/no-important";

// Extension to allow selectors to be prefixed with -> to apply to descendants of the selected element. For
// example, this:
//
// StyleSheet.create({
//   foo: {
//     fontWeight: '700',
//     '.bar': {
//       color: 'red'
//     },
//     '->.baz': {
//       color: 'blue'
//     }
//   }
// })
//
// would generate a stylesheet that looks something like:
//
// .sd-iHZvIS {
//   font-weight: 700;
// }
// .sd-iHZvIS.bar {
//   color: red;
// }
// .sd-iHZvIS .baz {
//   color: blue;
// }
const nestedSelectorHandler = {
  selectorHandler: function(selector, baseSelector, generateSubtreeStyles) {
    if (selector.substring(0, 2) === "->") {
      return generateSubtreeStyles(`${baseSelector} ${selector.substring(2)}`);
    }
  },
};

const { StyleSheet: newStyleSheet, css: newCss } = StyleSheet.extend([nestedSelectorHandler]);
export { newStyleSheet as StyleSheet, newCss as css };
