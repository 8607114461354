import React, { Component } from "react";
import { Elements } from "react-stripe-elements";
import SubscribeForm from "../../components/SubscribeForm";

//const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export default class Subscribe extends Component {
  render() {
    return (
      <Elements>
        <SubscribeForm plan={this.props.match.params.plan} discount={this.props.match.params.discount} />
      </Elements>
    );
  }
}
