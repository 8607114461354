import React from "react";

import { StyleSheet, css } from "aphrodite/no-important";
import { colors } from "../../styles";

const ThinHeader = ({ title }) => <h1 className={css(styles.hline)}>{title}</h1>;

const styles = StyleSheet.create({
  hline: {
    font: "1.0em sans-serif",
    textAlign: "center",
    borderBottom: "solid 1px #bbb",
    height: ".6em",
    marginBottom: "2.5em",

    ":first-line": {
      backgroundColor: colors.bgColor,
    },

    ":before": {
      content: "'\\0000a0 \\0000a0'",
    },

    ":after": {
      content: "'\\0000a0 \\0000a0'",
    },
  },
});

export default ThinHeader;
