import axios from "axios";

const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please contact info@becauselearning.com for help.";

export default class AxiosBackend {
  constructor({ url, method = "POST", wrapWith = null }) {
    this.url = url;
    this.method = method;
    this.wrapWith = wrapWith;
  }

  inferErrors = response => {
    let errors = {
      fields: {},
      other: [],
    };

    if (response.data.errors) {
      errors.fields = response.data.errors;
    }

    if (response.data.error) {
      errors.other = [response.data.error];
    }

    return errors;
  };

  wrapSubmission = submission => {
    submission = submission.toJSON();

    if (this.wrapWith) {
      submission = this.wrapWith(submission);
    }

    return submission;
  };

  submit = async submission => {
    let promise = axios({
      method: this.method,
      url: this.url,
      data: this.wrapSubmission(submission),
      withCredentials: true,
    });

    let response;

    try {
      response = await promise;
    } catch (e) {
      if (e.response && e.response.data && (e.response.data.errors || e.response.data.error)) {
        return {
          errors: this.inferErrors(e.response),
        };
      } else {
        return {
          errors: {
            other: [DEFAULT_ERROR_MESSAGE],
          },
        };
      }
    }

    return {
      data: response.data,
    };
  };
}
