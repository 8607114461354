import React from "react";
import PropTypes from "prop-types";
import I18n from "../../utils/i18n";

import { css } from "aphrodite/no-important";
import { Flex, Box } from "grid-styled";

import Button from "../Button";

import styles from "./styles";
import fnNull from "../../utils/fnNull";
import { defaultWidth } from "./defaults";

class TitledImage extends React.Component {
  inputRef = null;

  focus() {
    this.inputRef.focus();
  }

  selectAll() {
    this.inputRef.setSelectionRange(0, this.inputRef.value.length);
  }

  handleClickTitle = e => {
    if (this.props.canEditInPlace) {
      e.stopPropagation();
      this.props.startEditing(e);
    } else {
      this.props.onClick();
    }
  };

  render() {
    const cursor = this.props.onClick === fnNull ? "default" : "pointer";

    return (
      <Button
        className={css(styles.outerButton) + " " + this.props.className}
        style={{ cursor }}
        onClick={this.props.onClick}
      >
        <Box>
          <div className={css(styles.container)}>
            <img className={css(styles.image)} src={this.props.image} alt={this.props.title + " Image"} />
            <div className={css(styles.overlay)} />
            <Flex w="100%" align="flex-end" className={css(styles.title)}>
              {this.props.isEditing ? (
                this.renderEditInPlace()
              ) : (
                <Button
                  onClick={this.handleClickTitle}
                  className={css(styles.titleButton, this.props.canEditInPlace && styles.canEditText)}
                  tabIndex={this.props.tabIndex}
                >
                  {this.props.title === "" ? (
                    I18n.t("no_title_placeholder", { defaultValue: "[No Title]" })
                  ) : (
                    this.props.title
                  )}
                </Button>
              )}
            </Flex>
          </div>
        </Box>
      </Button>
    );
  }

  renderEditInPlace() {
    return (
      <Box>
        <input
          type="text"
          ref={el => (this.inputRef = el)}
          className={css(styles.input)}
          value={this.props.title}
          onChange={e => {
            this.props.setTitle(e.target.value);
          }}
          onKeyUp={e => {
            if (e.keyCode === 13) {
              this.props.stopEditing(e);
            }
          }}
          onBlur={e => {
            this.props.stopEditing(e);
          }}
          tabIndex={this.props.tabIndex}
        />
      </Box>
    );
  }
}

TitledImage.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  width: PropTypes.number,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,

  canEditInPlace: PropTypes.bool,
  isEditing: PropTypes.bool,
  startEditing: PropTypes.func,
  stopEditing: PropTypes.func,
  setTitle: PropTypes.func,
};

TitledImage.defaultProps = {
  width: defaultWidth,
  tabIndex: 0,
  onClick: fnNull,

  canEditInPlace: false,
  isEditing: false,
  startEditing: e => {},
  stopEditing: e => {},
  setTitle: t => {},
};

export default TitledImage;
