import React from "react";

import { StyleSheet, css } from "aphrodite/no-important";

/**
 * 
 * # Why "Yet Another Button"?
 * 
 * It's nice to have an accessible button that can be styled exactly. This
 * component is a regular `div` with a `button` role, and behaviors that
 * match regular buttons (e.g. can be activated with spacebar and enter,
 * as well as clicked).
 * 
 */

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyUp = event => {
      if (event.nativeEvent.keyCode === 32 || event.nativeEvent.keyCode === 13) {
        event.preventDefault();
        if (this.props.onClick) {
          this.props.onClick(event);
        }
      }
    };
  }

  blur() {
    this.buttonRef.blur();
  }

  focus() {
    this.buttonRef.focus();
  }

  render() {
    let { children, className, onClick, tabIndex, ...otherProps } = this.props;
    return (
      <div
        className={css(styles.button) + " " + className}
        ref={input => {
          this.buttonRef = input;
        }}
        role="button"
        onClick={onClick}
        onKeyUp={this.handleKeyUp}
        tabIndex={tabIndex || 0}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    cursor: "pointer",
    ":active": {
      outline: "none",
    },
  },
});

export default Button;
