import React from "react";
import PropTypes from "prop-types";
import I18n from "../../utils/i18n";

import { NavLink } from "react-router-dom";

import { StyleSheet, css } from "aphrodite/no-important";
import { Flex, Box } from "grid-styled";

import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
//import Divider from "material-ui/Divider";

import CurrentUser from "../../stores/CurrentUser";
import { userProfileURI } from "../../utils/uri";

import IconDown from "react-feather/dist/icons/chevron-down";

import { brand } from "../../styles";
import imgBecauseLearningLogo from "./because-learning-logo.svg";

const RESPONSIVE_SIZES = {
  big: 940,
  small: 750,
};

class Header extends React.Component {
  state = {
    profileMenuOpen: false,
  };

  constructor(props) {
    super(props);

    this.handleClickProfile = event => {
      // This prevents ghost click.
      event.preventDefault();

      this.setState({
        profileMenuOpen: true,
        profileMenuEl: event.currentTarget,
      });
    };

    this.handleProfileClose = () => {
      this.setState({
        profileMenuOpen: false,
      });
    };

    this.handleSignOut = () => {
      setTimeout(this.handleProfileClose, 200);
      if (this.props.onSignOut) {
        this.props.onSignOut();
      }
    };
  }

  render() {
    return (
      <Flex className={css(styles.container)} justify="space-between" align="center">
        <Box>
          <a href="https://www.becauselearning.com/">
            <img
              className={css(styles.logo)}
              src={imgBecauseLearningLogo}
              alt={I18n.t("because_learning_logo", { defaultValue: "BecauseLearning Logo" })}
            />
          </a>
        </Box>
        <Box className={css(styles.menu)}>
          <a href="https://www.becauselearning.com/parents" className={css(styles.link)}>
            {I18n.t("parents", { defaultValue: "Parents" })}
          </a>
          <a href="https://www.becauselearning.com/educators" className={css(styles.link)}>
            {I18n.t("educators", { defaultValue: "Educators" })}
          </a>
          <a href="https://www.becauselearning.com/" className={css(styles.link)}>
            {I18n.t("space_program", { defaultValue: "Space Program" })}
          </a>
          <NavLink exact to="/" className={css(styles.link)} activeClassName={css(styles.activeLink)}>
            {I18n.t("lessons", { defaultValue: "Lessons" })}
          </NavLink>
          {this.props.isSignedIn ? this.renderSignedIn() : this.renderNotSignedIn()}
          {this.props.isSignedIn && this.renderSignedInMenu()}
        </Box>
      </Flex>
    );
  }

  renderSignedIn() {
    return (
      // use anchor tag to get same styling as other menu items
      <a href="#" onClick={this.handleClickProfile} className={css(styles.link)} style={{ position: "relative" }}>
        {this.props.signedInName}
        <IconDown className={css(styles.signinIcon)} />
      </a>
    );
  }

  renderNotSignedIn() {
    return (
      <NavLink
        exact
        to="/sign_in"
        className={css(styles.link, styles.signinLink)}
        activeClassName={css(styles.activeLink)}
      >
        {I18n.t("sign_in", { defaultValue: "Sign In" })}
      </NavLink>
    );
  }

  renderSignedInMenu() {
    return (
      <Popover
        open={this.state.profileMenuOpen}
        anchorEl={this.state.profileMenuEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        targetOrigin={{ horizontal: "left", vertical: "top" }}
        onRequestClose={this.handleProfileClose}
      >
        <Menu>
          {/*<MenuItem primaryText="Create an Experiment" />*/}
          <MenuItem
            href={userProfileURI(CurrentUser.user && CurrentUser.user.id)}
            primaryText={I18n.t("my_profile", { defaultValue: "My Profile" })}
          />
          <MenuItem onClick={this.handleSignOut} primaryText={I18n.t("sign_out", { defaultValue: "Sign Out" })} />
        </Menu>
      </Popover>
    );
  }
}

Header.propTypes = {
  isSignedIn: PropTypes.bool,
  signedInName: PropTypes.string,
  onSignOut: PropTypes.func,
};

Header.defaultProps = {
  isSignedIn: false,
  signedInName: I18n.t("user", { defaultValue: "User" }),
  onSignOut: () => {},
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: brand.aqua,
    height: 125,
    paddingLeft: 100,
    paddingRight: 100,
    ["@media screen and (max-width: " + RESPONSIVE_SIZES.big + "px)"]: {
      height: 55,
    },
    ["@media screen and (max-width: " + RESPONSIVE_SIZES.small + "px)"]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },

  link: {
    padding: "5px 15px",
    textDecoration: "none",
    color: brand.white,
    fontSize: "1em",
    fontFamily: "Avenir Next, Helvetica, Sans",
    fontWeight: 500,
    letterSpacing: "0.75px",
    ["@media screen and (max-width: " + RESPONSIVE_SIZES.big + "px)"]: {
      fontSize: "0.75em",
      letterSpacing: "0.25px",
    },
  },

  signinLink: {
    border: "2px solid " + brand.red,
    borderRadius: "5px",
    backgroundColor: brand.red,
    color: brand.white,
    marginLeft: 15,
  },

  signinIcon: {
    position: "absolute",
    marginLeft: "3px",
    top: "3px",
    width: "20px",
    height: "20px",
    ["@media screen and (max-width: " + RESPONSIVE_SIZES.big + "px)"]: {
      top: "0px",
    },
  },

  activeLink: {
    color: brand.blue,
    borderBottom: "2px solid " + brand.blue,
  },

  menu: {
    paddingBottom: 50,
    ["@media screen and (max-width: " + RESPONSIVE_SIZES.big + "px)"]: {
      paddingBottom: 0,
    },
    ["@media screen and (max-width: " + RESPONSIVE_SIZES.small + "px)"]: {
      display: "none",
    },
  },

  logo: {
    width: 200,
    ["@media screen and (max-width: " + RESPONSIVE_SIZES.big + "px)"]: {
      width: 110,
      paddingTop: 4,
    },
  },
});

export default Header;
