import React from "react";

import { StyleSheet, css } from "aphrodite/no-important";
import { Flex, Box } from "grid-styled";
import throttle from "lodash.throttle";

import IconSave from "react-feather/dist/icons/save";
import IconUpload from "react-feather/dist/icons/upload";
import IconCheck from "react-feather/dist/icons/check";
import IconClose from "react-feather/dist/icons/x";

import Button from "../../components/Button";
import UnitTile from "../../components/UnitTile";
import SearchBar from "../../components/SearchBar";
import GradeSelect from "../../components/GradeSelect";

import { searchExperimentsURI, createUnitURI, status } from "../../utils/uri";

import { brand, colors } from "../../styles";

const unitWithDefaults = () => {
  return {
    title: "New Unit",
    image: "/images/blank-unit.png",
    grades: [],
    lessons: [],
  };
};

class AssembleUnit extends React.Component {
  state = {
    file: null,
    newUnit: unitWithDefaults(),
    newUnitGrades: {
      elementary: false,
      middle: false,
      high: false,
    },
    newLessonSearchTerm: "",
    newLessonSearchResults: null,
    isEditingNewUnitTitle: false,
    saveSuccess: false,
    saveFail: false,
    saveFailReason: null,
  };

  unitTileRef = null;

  clearUnit = () => {
    this.setState({
      newUnit: unitWithDefaults(),
      saveSuccess: false,
      saveFail: false,
    });
  };

  setUnitTitle = newTitle => {
    this.setState({ newUnit: { ...this.state.newUnit, title: newTitle } });
  };

  setNewUnitGrade = grade => {
    let updatedGrades = Object.assign({}, this.state.newUnitGrades);
    updatedGrades[grade] = !updatedGrades[grade];
    this.setState({ newUnitGrades: updatedGrades });
  };

  startEditingUnitTitle = e => {
    // TiledImage will get the message to swap text for an input
    this.setState({ isEditingNewUnitTitle: true }, () => {
      // Focus and select text on the input, after React has had a chance to
      // swap the text for an input element
      setTimeout(() => {
        this.unitTileRef.focusTitle();
      }, 100);
    });
  };

  stopEditingUnitTitle = e => {
    this.setState({
      isEditingNewUnitTitle: false,
    });
  };

  saveUnit = async () => {
    const formData = new FormData();
    if (this.state.newUnit) {
      const lessonIDs = this.state.newUnit.lessons.map(lesson => {
        return lesson.id;
      });
      const lessonsFeatured = this.state.newUnit.lessons.map(lesson => {
        return lesson.featured;
      });
      const grades = Object.keys(this.state.newUnitGrades).filter(key => {
        return this.state.newUnitGrades[key];
      });
      formData.append("title", this.state.newUnit.title);
      formData.append("lessons", JSON.stringify(lessonIDs));
      formData.append("lessons_featured", JSON.stringify(lessonsFeatured));
      formData.append("grades", JSON.stringify(grades));
    } else {
      console.error("no newUnit data");
    }

    if (this.state.file) {
      // event.target.files[0] data going here
      formData.append("thumbnail_image", this.state.file);
    } else {
      console.log("no image to save [ok]");
    }

    const params = {
      method: "POST",
      body: formData,
      credentials: "include",
    };

    try {
      const fetchResponse = await fetch(createUnitURI(), params);
      const response = await status(fetchResponse);
      await response.json();
      this.setState({
        saveSuccess: true,
        saveFail: false,
      });
    } catch (e) {
      this.setState({
        saveSuccess: false,
        saveFail: true,
        saveFailReason: e.message,
      });
      console.error("uh oh", e);
    }
  };

  handleImageUploadChange = e => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      let newUnit = Object.assign({}, this.state.newUnit);
      newUnit.image = reader.result;
      this.setState({ file, newUnit });
    };

    reader.readAsDataURL(file);
  };

  addLesson = lesson => {
    if (this.state.newUnit) {
      let newUnit = Object.assign({}, this.state.newUnit);
      // Create copy of array (treat original as immutable)
      newUnit.lessons = newUnit.lessons.slice();
      newUnit.lessons.push(lesson);
      this.setState({ newUnit });
    } else {
      console.error("Unable to add lesson to null Unit");
    }
  };

  handleClickLesson = clickedLesson => {
    let newUnit = Object.assign({}, this.state.newUnit);
    // Create copy of array (treat original as immutable)
    newUnit.lessons = newUnit.lessons.slice();
    let lesson = null;
    for (let i = 0; i < newUnit.lessons.length; i++) {
      if (newUnit.lessons[i].id === clickedLesson.id) {
        lesson = newUnit.lessons[i];
      }
    }
    if (lesson !== null) {
      lesson.featured = !lesson.featured;
    }
    this.setState({ newUnit });
  };

  setLessonSearchValue = value => {
    this.setState({
      newLessonSearchTerm: value,
    });
    this.throttledSearch(value);
  };

  resetLessonSearch = () => {
    this.setState({
      newLessonSearchTerm: "",
      newLessonSearchResults: null,
    });
  };

  search = async value => {
    const result2lesson = result => {
      return {
        id: result.id,
        title: result.title,
        description: result.description,
        image: result.thumbnail_image,
        minutes: result.estimated_minutes,
      };
    };

    const params = {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };

    try {
      // results for the search
      const fetchResponse = await fetch(searchExperimentsURI({ query: value }), params);
      const response = await status(fetchResponse);
      const results = (await response.json()).map(result => {
        return result2lesson(result);
      });

      this.setState({
        newLessonSearchResults: results,
      });
    } catch (e) {
      console.error(e);
    }
  };

  throttledSearch = throttle(this.search, 500, {
    leading: true,
    trailing: true,
  });

  render() {
    return (
      <Flex justify="center" className={css(styles.container)} wrap={true}>
        <Box>
          <Flex align="center" direction="column">
            <Box>{this.renderNewUnit()}</Box>
            <Box>{this.renderSaveButton()}</Box>
          </Flex>
        </Box>
        <Box>{this.renderRightPanel()}</Box>
      </Flex>
    );
  }

  renderSaveButton() {
    return (
      <Flex direction="column" align="center">
        <Box>
          <Button className={css(styles.button)} onClick={this.saveUnit} tabIndex={2}>
            <Flex align="center">
              <Box className={css(styles.icon)}>
                <IconSave />
              </Box>
              <Box className={css(styles.buttonLabel)}>Save Unit</Box>
              {this.state.saveSuccess && (
                <Box className={css(styles.saveSuccess)}>
                  <IconCheck />
                </Box>
              )}
              {this.state.saveFail && (
                <Box className={css(styles.saveFail)}>
                  <IconClose />
                </Box>
              )}
            </Flex>
          </Button>
        </Box>
        {this.state.saveFail && <Box className={css(styles.saveFailReason)}>{this.state.saveFailReason}</Box>}
      </Flex>
    );
  }

  renderNewUnit() {
    return (
      <UnitTile
        {...this.state.newUnit}
        ref={el => (this.unitTileRef = el)}
        isScrollable={true}
        tabIndex={1}
        onClickLesson={this.handleClickLesson}
        canEditInPlace={true}
        isEditing={this.state.isEditingNewUnitTitle}
        startEditing={this.startEditingUnitTitle}
        stopEditing={this.stopEditingUnitTitle}
        setTitle={this.setUnitTitle}
      />
    );
  }

  renderRightPanel() {
    return (
      <Flex className={css(styles.rightPanel)} direction="column" justify="center">
        <Box>{this.renderFileUploadPanel()}</Box>
        <Box className={css(styles.heading)}>Grade Level(s):</Box>
        <Box>{this.renderSetGradePanel()}</Box>
        <Box className={css(styles.heading)}>Add Lessons:</Box>
        <Box>{this.renderAddLessonPanel()}</Box>
      </Flex>
    );
  }

  renderAddLessonPanel() {
    return (
      <Flex className={css(styles.addLessonPanel)} direction="column">
        <Box>
          <SearchBar
            value={this.state.newLessonSearchTerm}
            setValue={this.setLessonSearchValue}
            onReset={this.resetLessonSearch}
            tabIndex={1}
          />
        </Box>
        {this.state.newLessonSearchResults !== null && (
          <Box className={css(styles.searchResultBox)}>
            {this.state.newLessonSearchResults.map(lesson => {
              return (
                <Button
                  key={lesson.id}
                  className={css(styles.searchResult)}
                  onClick={() => {
                    this.addLesson(lesson);
                  }}
                >
                  {lesson.title}
                </Button>
              );
            })}
          </Box>
        )}
      </Flex>
    );
  }

  renderFileUploadPanel() {
    return <div className={css(styles.fileUploadPanel)}>{this.renderFileUploadForm()}</div>;
  }

  renderFileUploadForm() {
    return (
      <Button className={css(styles.button, styles.uploadButtonContainer)} tabIndex={1}>
        <Flex align="center" justify="center">
          <Box className={css(styles.icon)}>
            <IconUpload />
          </Box>
          <Box className={css(styles.buttonLabel)}>Upload Image</Box>
        </Flex>
        <input className={css(styles.uploadButton)} type="file" tabIndex="-1" onChange={this.handleImageUploadChange} />
      </Button>
    );
  }

  renderSetGradePanel() {
    return (
      <GradeSelect
        className={css(styles.gradeSelect)}
        wrap={true}
        justify="center"
        selected={this.state.newUnitGrades}
        setGrade={this.setNewUnitGrade}
        tabIndex={1}
      />
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: 30,
  },

  icon: {
    height: 24,
  },

  buttonLabel: {
    marginLeft: 5,
  },

  button: {
    color: colors.buttonFgColor + " !important",
    backgroundColor: colors.buttonBgColor + " !important",
    border: "2px solid " + colors.buttonBorderColor + " !important",
    borderRadius: 4,
    boxShadow: "0px 6px 40px -6px rgba(0,0,0,0.35)",
    padding: "7px 8px 5px 5px",
    height: "28px",
    lineHeight: "28px",
  },

  unitSection: {
    marginTop: 30,
  },

  heading: {
    fontWeight: 500,
    fontSize: "15px",
    marginTop: 45,
    marginLeft: 15,
  },

  addLessonPanel: {
    minWidth: 250,
    paddingRight: 15,
    margin: "0px 15px 60px 15px",
  },

  fileUploadPanel: {
    minWidth: 250,
    marginTop: 15,
  },

  uploadButtonContainer: {
    position: "relative",
    overflow: "hidden",
    width: 200,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },

  uploadButton: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: 0,
    padding: 0,
    fontSize: 32,
    cursor: "pointer",
    opacity: 0,
    filter: "alpha(opacity=0)",
  },

  searchResultBox: {
    borderLeft: "1px solid " + brand.light,
    borderBottom: "1px solid " + brand.light,
    borderRight: "1px solid " + brand.light,
    backgroundColor: brand.white,
    overflow: "scroll",
    maxHeight: 200,
  },

  searchResult: {
    display: "block",
    textAlign: "left",
    padding: "3px 15px 1px 15px",
    cursor: "pointer",
    ":hover": {
      backgroundColor: brand.blue,
      color: brand.white,
    },
    ":focus": {
      outline: "none",
      backgroundColor: brand.blue,
      color: brand.white,
    },
  },

  gradeSelect: {
    marginRight: 15,
    borderRadius: 4,
    marginTop: 10,
  },

  saveSuccess: {
    color: brand.aqua,
    position: "relative",
    top: 2,
    paddingLeft: 7,
  },

  saveFail: {
    color: brand.red,
    position: "relative",
    top: 2,
    paddingLeft: 7,
  },

  saveFailReason: {
    color: brand.red,
    border: "2px solid " + brand.red + " !important",
    padding: 10,
    marginTop: 15,
  },
});

export default AssembleUnit;
